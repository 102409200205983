import mvtTextbox from '@/common/components/mvttextbox/mvt-textbox.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'mvtForgetPassword',
    inject: ['$eventBus'],
    components: {
        mvtTextbox
    },
    data(){
        return {
            formState: '',
            errorMessage: '',
            username: ''
        };
    },
    computed:{
        ...mapGetters('glb', ['glb']),
    },
    methods: {
        ...mapActions('glb', ['forgotPassword']),
        switchComponent: function(name) {
            this.$emit('switchComponent', name);
        },
        submit: function() {
            var that = this;
            this.formState = 'submitting';
            this.errorMessage = '';

            this.forgotPassword({
                    username: that.username,
                    trigger: that.$options.name
                })
                .then(resp => {
                    let status = resp && resp.status;
                    if (status && status.code === 0) {
                        that.formState = 'submitted';
                        that.$eventBus.$emit('dialog-center', {name: 'mvtConfirm', opt: {
                            title: 'Email Sent',
                            message: 'Check your email for a message from Movoto to reset your password.'
                        }, trigger: that.$options.name});
                    }else{
                        that.formState = 'rejected';
                        that.errorMessage = status.msg;
                    }
                })
                .catch(err => {
                    that.formState = '';
                });
            }
    }
};
