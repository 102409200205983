import { mapActions, mapGetters } from 'vuex';
import mvtAuthenticate from '@/common/components/mvtauthenticate/mvt-authenticate.vue';
import { getGa4Trigger } from '../../../../widget/spec/ga4helper';

export default {
    name: 'mvtPopSignin',
    root: true,
    inject: ['$eventBus'],
    components: {
        mvtAuthenticate,
    },
    computed: {
        ...mapGetters('glb', ['glb', 'getSplit']),
        getUserId() {
            return this.glb.user.id;
        },
    },
    methods: {
        ...mapActions('glb', ['oneTapGoogleLogin']),

        inital() {
            var that = this;
            if (!this.isSafari) {
                $.loadGoogleOneTapSignin().then(function () {
                    window.google.accounts.id.initialize({
                        client_id: that.glb.googleLoginClientId,
                        cancel_on_tap_outside: false,
                        context: 'use',
                        ux_mode: 'redirect',
                        login_uri: that.glb.appUrl + 'logingoogle/',
                        use_fedcm_for_prompt: true,
                        callback: function (data) {
                            that.handleOneTap(data);
                        },
                    });
                    window.google.accounts.id.prompt();
                });
            }
        },
        handleOneTap(data) {
            if (data) {
                data.userType = this.glb.pageInfo.userType;
                data.trigger = getGa4Trigger(this.$options.name);
            }

            this.oneTapGoogleLogin(data).then((res) => {
                window.__INITIAL_STATE__.userID = res && res.data && res.data.userId;
                this.$eventBus.$emit('eventbus-context-update');
                this.$eventBus.$emit('eventbus-authenticated');
                if (res.data.newUserOneTap) {
                    $.setStorage('NEWUSERONETAP', 'true');
                }
                setTimeout(function () {
                    window.location.reload();
                }, 1500);
            });
        },
        openPop() {
            $.setStorage('shouldPopSign', 1);
            this.close = false;
        },
        closePop() {
            $.setStorage('shouldPopSign', 0);
            this.close = true;
        },
        appleLogin() {
            this.$eventBus.$emit('eventbus-login-apple', { trigger: this.$options.name });
        },
        popSignin() {
            this.$eventBus.$emit('eventbus-signup', { trigger: this.$options.name });
            this.closePop();
        },
    },
    mounted: function () {
        if (this.getUserId) {
            $.setStorage('shouldPopSign', 0);
            $.setStorage('nextPopSignPopWaitTime', new Date().setFullYear(9999));
            return null;
        }
        var hasTouch = 'ontouchstart' in window;
        var clickEvent = hasTouch ? 'touchstart' : 'mouseenter';
        this.isSafari = $.browserType() === 'safari';
        $(document).ready(() => {
            $(document).one(clickEvent, () => {
                this.inital();
            });
        });
        this.$eventBus.$on('eventbus-closePopupSignIn', () => {
            this.closePop();
        });
    },
    data: function () {
        return {
            showLogin: false,
            isSafari: false,
            close: true,
        };
    },
    beforeUnmount() {
        this.$eventBus.$off('eventbus-closePopupSignIn');
    },
};
