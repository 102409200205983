import { nextTick } from 'vue'

export default {
    name: 'mvtTextbox',
    mounted() {
        var defaultText = '';
        var $this = $(this.$el);
        var $input = $this.find('input');
        var _switchLabel = function() {
            if ($input.val()) {
                $this.addClass('focus');
            } else {
                $this.removeClass('focus');
            }
        };
        if (!$input.length) {
            $input = $this.find('textarea');
        }
        $input.on('focusin', function() {
            $this.addClass('focus');
        });
        $input.on('focusout', function() {
            if (!$input.val() && !defaultText) {
                $this.removeClass('focus');
            }
        });
        $input.on('change', _switchLabel);
        setTimeout(() => {
            _switchLabel();
        }, 100);
    }
};
