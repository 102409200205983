<template>
    <div class="grid xs-grid-cols-1 gap-3">
        <h3 class="f4 text-center text-bold">Sorry, there was a problem loading the popup.</h3>
        <div class="f5">
            <span v-if="options && options.dlgRetryCount > 1">
                We noticed you haven't reload the page on your last try and retry alone is not working for you due to some network problem.
            </span>
            <span v-else>It looks like you had a network glitch.</span>
            We highly recommend that you reload the page and redo what you tried earlier.
        </div>
    </div>
</template>
<script>
export default {
    name: 'loadDialogError',
    props: ['options']
};
</script>