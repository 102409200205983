const googleMapServer = 'https://maps.googleapis.com';
const iconTypeMapper = {
    SINGLE_FAMILY_HOUSE: 'sf',
    CONDO: 'condo',
    MULTI_FAMILY: 'multi',
    LAND: 'land',
    MOBILE: 'mobile',
    OTHER: 'other',
    // this case is currently not in use
    COMMERCIAL: 'other',
};
const iconPoiMapper = {
    cafes: 'cafes',
    grocery: 'grocery',
    parks: 'parks',
    restaurants: 'restaurants',
    shopping: 'shopping',
    gym: 'gym',
    transits: 'transits',
    school: 'school',
};

const newIconPoiMapper = {
    cafes: 'cafes',
    grocery: 'grocery_light',
    parks: 'parks',
    restaurants: 'restaurants_brand',
    shopping: 'shopping',
    gym: 'gym',
    transits: 'transits',
    school: 'school',
};

export function getIconUrl(type, cdnUrl, newDpp) {
    if(newDpp){
        if(newIconPoiMapper[type]){
            const markerPath = `images/desktop/poimarker/${newIconPoiMapper[type]}.png`;
            return `${cdnUrl}${markerPath}`;
        }
    }
    if (iconTypeMapper[type]) {
        let path = `images/desktop/pin/icon-${newDpp ? 'new-': ''}pin-${iconTypeMapper[type]}.png`;
        return `${cdnUrl}${path}`;
    } else if (iconPoiMapper[type]) {
        const markerPath = `images/desktop/poimarker/${iconPoiMapper[type]}.png`;
        return `${cdnUrl}${markerPath}`;
    } else {
        return '';
    }
}

export function getStaticStreetPath({ address, lat, lng, width = 480, height = 360, id, resolution = 1 }) {
    var streetMapPath = '/maps/api/streetview?location=';

    if (address) {
        streetMapPath += encodeURIComponent(address);
    } else if (lat && lng) {
        streetMapPath += lat + ',' + lng;
    }
    streetMapPath += '&size=' + Math.ceil(width * resolution) + 'x' + Math.ceil(height * resolution);
    streetMapPath += '&' + id;
    return streetMapPath;
}

export function getStaticMapPath({ type = 'terrain', address, lat, lng, width = 480, height = 360, zoom, markers, id, markerCdnUrl, resolution = 1, newDpp = false, anchor = '' }) {
    var staticMapPath = '/maps/api/staticmap?center=';
    if (address) {
        staticMapPath += encodeURIComponent(address);
    } else if (lat && lng) {
        staticMapPath += lat + ',' + lng;
    }
    staticMapPath += '&maptype=' + type;
    staticMapPath += '&size=' + Math.ceil(width * resolution) + 'x' + Math.ceil(height * resolution);
    if (zoom) {
        staticMapPath += '&zoom=' + zoom;
    }

    //expensive! google going to fetch icons from our cdn then generate the map image.
    if (markers && markers.length) {
        markers.forEach((item) => {
            if (item.type && markerCdnUrl) {
                let iconStr = encodeURIComponent(getIconUrl(item.type, markerCdnUrl, newDpp));
                staticMapPath += '&markers=';
                if (anchor) {
                    staticMapPath += 'anchor:' + anchor + '|';
                }
                staticMapPath += 'icon:' + iconStr + '|' + item.lat + ',' + item.lng;
            } else {
                let markerStr = '&markers=';
                let params = [];
                if (anchor) {
                    params.push('anchor:' + anchor);
                }
                if (item.label) {
                    params.push('label:' + item.label);
                }
                if (item.color) {
                    params.push('color:' + item.color);
                }
                params.push(item.lat + ',' + item.lng);
                markerStr += encodeURIComponent(params.join('|'));
                staticMapPath += markerStr;
            }
        });
    }
    staticMapPath += '&' + id;

    return staticMapPath;
}

export function getStaticStreetUrl(path) {
    return googleMapServer + path;
}

export function getStaticMapUrl(path) {
    return googleMapServer + path;
}

export default {
    getStaticStreetPath,
    getStaticMapPath,
    getStaticStreetUrl,
    getStaticMapUrl,
};
