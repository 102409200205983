<template>
    <div class="authenticate-panel grid xs-grid-cols-1 gap-3">
        <h3 class="f3">
            Join Movoto
        </h3>
        <mvtSocial :trigger="options.trigger" :requestName="options.requestName"/>
        <div>
            Or with Email:
        </div>
        <form class="form form-default grid xs-grid-cols-1 gap-3" :class="formState" v-submit="submit" novalidate>
            <mvtTextbox>
                <div class="input">
                    <label>Name</label>
                    <input type="text" v-inputtrim name="name" v-model="fullname"  v-validate="'required|name'" />
                </div>
            </mvtTextbox>
            <mvtTextbox>
                <div class="input">
                    <label>Email</label>
                    <input type="email" v-inputtrim name="email" v-model="email"  v-validate="'required|email'" />
                </div>
            </mvtTextbox>
            <mvtTextbox>
                <div class="input border">
                    <label>Password</label>
                    <input type="password" data-dd-privacy="mask-user-input" name="password" v-model="password"  v-validate="'required|password'" />
                </div>
            </mvtTextbox>
            <div v-if="errorMessage" class="text-error">{{errorMessage}}</div>
            <button class="btn primary large" type="submit" >Join</button>
        </form>
        <div class="f7" v-html="molang('dpp.mvtEmail.movotoTerms1')">
        </div>
        <div>
             <a class="link" @click="switchComponent('mvtLogin')" >Already have an account? Sign In.</a>
        </div>

    </div>
</template>
<script src="./mvt-signup.js"></script>