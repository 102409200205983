<template>
    <mvtDialog ref="dialog" @hidden="hide" v-ga:dialog :class="componentName" :comp="componentName">
        <component v-slot:default v-if="componentTemplate" :options="componentOpt" :is="componentTemplate" :comp="componentName"/>
    </mvtDialog>
    <div @click="hideMessage" class="mvt-dialogcenter-tip p-x-3 p-y-2 inline corner default active" :class="{show: showTip}">
        <div class="flex center middle">
            <i class="f2 icon-check-circle-outside text-brand"></i>
            <div class="f6" v-html="message"></div>
        </div>
    </div>
</template>

<script src="./mvt-dialogcenter.js"></script>
<style lang="scss">
    @import "./mvt-dialogcenter.scss";
</style>