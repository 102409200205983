import mvtSocial from '@/common/components/mvtsocial/mvt-social.vue';
import mvtTextbox from '@/common/components/mvttextbox/mvt-textbox.vue';
import { mapActions, mapGetters } from 'vuex';
import { getGa4Trigger } from '../../../../widget/spec/ga4helper';

export default {
    name: 'mvtSignUp',
    inject: ['$eventBus'],
    components: {
        mvtTextbox,
        mvtSocial,
    },
    props: {
        options: {
            type: Object,
            default: {
                trigger: '',
                requestName: '',
            },
        },
    },
    data() {
        return {
            formState: '',
            errorMessage: '',
            fullname: '',
            email: '',
            password: '',
        };
    },
    computed: {
        ...mapGetters('glb', ['glb']),
        isTriggerByAuth() {
            return this.options.trigger !== 'mvtHeader' && this.options.trigger !== 'mvtFooter';
        },
    },
    methods: {
        ...mapActions('glb', ['signup']),
        switchComponent(name) {
            this.$emit('switchComponent', name);
        },
        submit() {
            this.formState = 'submitting';
            this.errorMessage = '';

            this.signup({
                fullname: this.fullname,
                email: this.email,
                password: this.password,
                userType: this.glb.pageInfo.userType,
                trigger: getGa4Trigger(this.$options.trigger, this.$options.requestName),
            })
                .then((resp) => {
                    let status = resp && resp.status;
                    if (status && status.code === 0) {
                        this.formState = 'submitted';
                        this.$eventBus.$emit('eventbus-authenticated', this.options.requestName);
                        if (!this.isTriggerByAuth) {
                            this.$eventBus.$emit('dialog-center', {
                                name: 'mvtConfirm',
                                opt: {
                                    componentName: 'mvtConfirmAuth',
                                },
                                trigger: this.$options.name,
                            });
                        } else {
                            this.$eventBus.$emit('close-dialog', this.$options.name);
                        }
                    } else {
                        this.formState = 'rejected';
                        this.errorMessage = status.msg;
                    }
                })
                .catch(() => {
                    this.formState = '';
                });
        },
    },
};
