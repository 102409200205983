import { mapGetters } from 'vuex';
import { getStaticStreetPath, getStaticMapPath } from '../../../../widget/spec/mapHelper';
function loadImg(img) {
    var $img = $(img);
    var imgSrc = $img.attr('data-map');
    if (imgSrc) {
        $.loadImg($img, imgSrc);
    }
    $img.removeAttr('data-map');
}
function setImg(img, src) {
    var $img = $(img);
    $img.attr('data-map', src);
    if (window.IntersectionObserver) {
        if (!window.observerMap) {
            const options = {
                rootMargin: '0% 0% 0% 0%',
                threshold: 0,
            };
            window.observerMap = new IntersectionObserver(function (entries) {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) {
                        return;
                    }
                    loadImg(entry.target);
                    window.observerMap.unobserve(entry.target);
                });
            }, options);
        }
        window.observerMap.observe(img);
    } else {
        loadImg(img);
    }
}
export default {
    name: 'mvtMapStatic',
    inject: ['$eventBus'],
    props: {
        height: {
            default: 360,
        },
        width: {
            default: 480,
        },
        type: {
            default: 'terrain',
        },
        streetViewDefautType: {
            default: 'satellite',
        },
        address: {
            default: '',
        },
        lat: {
            default: 0,
        },
        lng: {
            default: 0,
        },
        zoom: {
            default: 14,
        },
        markers: {
            default() {
                return [];
            },
        },
        autofit: {
            default: false,
        },
        cacheFirst: {
            default: false,
        },
        resolution: {
            default: 1,
        },
        index: {
            default: 0,
        },
        placeholder: {
            default: '',
        },
        alt: {
            default: 'Map Image',
        },
        newDpp: false,
        anchor: {
            default: '',
        },
    },
    watch: {
        markers: function () {
            this.loadMapSrc(this.address, this.lat, this.lng, this.markers);
        },
    },
    data() {
        return {
            _address: '',
            _lng: null,
            _lat: null,
            _markers: [],
            _width: 0,
            _height: 0,
        };
    },
    methods: {
        getMapUrl(path, resolution) {
            var srcDfd = null,
                key = encodeURIComponent(this._address || this._lat + '_' + this._lng) + '_' + this.type;

            if (this.cacheFirst) {
                srcDfd = window.hashTable[key];
            }

            if (!srcDfd) {
                srcDfd = new Promise((resolve) => {
                    if (this.glb.isDevelopment) {
                        resolve(`https://maps.googleapis.com${path}`);
                    } else {
                        const url = `${this.glb.appUrl}webservices/getgooglemapurlwithsignature/`;
                        const body = { url: path };
                        fetch(url, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json; charset=UTF-8',
                            },
                            body: JSON.stringify(body),
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.url) {
                                    resolve(`https://maps.googleapis.com${data.url}`);
                                }
                            });
                    }
                    window.hashTable[key] = srcDfd;
                });
            }
            return srcDfd;
        },
        setGSMapParams(type, resolution = 1) {
            let params = {
                lat: this._lat,
                lng: this._lng,
                address: this._address,
                zoom: this.zoom,
                width: this._width,
                height: this._height,
                markers: this._markers,
                markerCdnUrl: `${!this.glb.cdnUrl.includes(':3015/') ? this.glb.cdnUrl : 'https://www.movoto.com/novaimgs/'}`,
                type: type,
                id: this.glb.googleMapKey,
                resolution: resolution,
                newDpp: this.newDpp,
            };
            if (this.anchor) {
                params.anchor = this.anchor;
            }
            return getStaticMapPath(params);
        },
        setStreetViewParams(resolution = 1, width = null, height = null) {
            let params = {
                lat: this._lat,
                lng: this._lng,
                address: this._address,
                width: this._width,
                height: this._height,
                id: this.glb.googleMapKey,
                resolution: resolution,
            };
            return getStaticStreetPath(params);
        },
        initialStreetView(resolution) {
            this.getMapUrl(this.setStreetViewParams()).then((src) => {
                setImg(this.$refs.img, src);
                if (this.resolution > 1) {
                    this.getMapUrl(this.setStreetViewParams(this.resolution)).then((src_l) => {
                        this.$emit('urlLoaded', {
                            src: src,
                            src_l: src_l,
                            index: this.index,
                        });
                    });
                }
            });
        },
        initialNormalMapView(type) {
            this.getMapUrl(this.setGSMapParams(type)).then((src) => {
                setImg(this.$refs.img, src);
                if (this.resolution > 1) {
                    this.getMapUrl(this.setGSMapParams(type), this.resolution).then((src_l) => {
                        this.$emit('urlLoaded', {
                            src: src,
                            src_l: src_l,
                            index: this.index,
                        });
                    });
                }
            });
        },
        loadMapSrc(address, lat, lng, markers) {
            if (address) {
                this._address = address;
            }
            if (lat && lng) {
                this._lat = lat;
                this._lng = lng;
            }
            if (markers) {
                this._markers = markers;
            }
            var that = this;
            if (this.type === 'streetview') {
                $.hasStreetView({
                    address: this._address,
                    lat: this._lat,
                    lng: this._lng,
                    callback: function (hasSteetView) {
                        if (hasSteetView) {
                            that.initialStreetView();
                        } else if (that.streetViewDefautType) {
                            that.initialNormalMapView(that.streetViewDefautType);
                        } else {
                            that.$emit('error');
                        }
                    },
                });
            } else {
                this.initialNormalMapView(this.type);
            }
        },
    },
    computed: {
        ...mapGetters('glb', ['glb', 'getSplit']),
        placeholderUrl() {
            return this.placeholder || 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        },
    },
    beforeMount() {
        this._width = this.width;
        this._height = this.height;
    },
    mounted() {
        if (this.autofit && this.$el.offsetWidth > 0) {
            this._width = this.$el.offsetWidth;
        }
        this.loadMapSrc(this.address, this.lat, this.lng, this.markers);
    },
    beforeUnmount() {
        window.observerMap && window.observerMap.unobserve(this.$refs.img);
    },
};
