<template>
    <div  class="authenticate-panel grid xs-grid-cols-1 gap-3">
        <div v-if="profile" class="dialog-profile">
            <i v-if="/^icon-/.test(profile)" class="circle  toggle  huge" :class="profile" />
            <img v-else :src="profile" class="circle  toggle  huge" />
        </div>
        <div class="text-center grid xs-grid-cols-1">
            <h3 v-if="formInfo.title" class="f3 text-bold" data-role="title" v-html="formInfo.title"></h3>
            <SplitTreatments v-if="(isSaveSearch || isSaveProperty || isClaimHome || isSimpleDppClaim)" :names="['movoto-signup-value-CW-1955']">
                <template #default="{ treatments, isReady }">
                    <div v-if="isReady && treatments['movoto-signup-value-CW-1955'] === 'on'">
                        <div class="fit-content" v-if="isSaveSearch">
                            <div class="text-left m-b-1"><i class="f3 text-main icon-check-circle-outside"></i> Get updates when new listings hit the market</div>
                            <div class="text-left"><i class="f3 text-main icon-check-circle-outside"></i> Save and update your search criteria</div>
                        </div>
                        <div class="fit-content" v-if="isSaveProperty">
                            <div class="text-left m-b-1"><i class="f3 text-main icon-check-circle-outside"></i> Keep track of your favorite homes</div>
                            <div class="text-left"><i class="f3 text-main icon-check-circle-outside"></i> Get real-time listing updates</div>
                        </div>
                        <div class="fit-content" v-if="isClaimHome || isSimpleDppClaim">
                            <div class="text-left m-b-1"><i class="f3 text-main icon-check-circle-outside"></i> Uncover Hidden Savings</div>
                            <div class="text-left m-b-1"><i class="f3 text-main icon-check-circle-outside"></i> View Neighborhood Sales & Trends</div>
                            <div class="text-left"><i class="f3 text-main icon-check-circle-outside"></i> Get Maintenance Help</div>
                        </div>
                    </div>
                    <div v-else-if="formInfo.subTitle" class="f5" v-html="formInfo.subTitle"></div>
                </template>
            </SplitTreatments>
            <div v-else-if="formInfo.subTitle" class="f5" v-html="formInfo.subTitle"></div>
        </div>
        <form class="form form-default grid xs-grid-cols-1 gap-3" :class="glb.authFormState"  v-submit="submitSignup">
            <mvtTextbox>
                <div class="input">
                    <label>Email</label>
                    <input type="email" v-model="email"  v-validate="'required|email'" />
                </div>
            </mvtTextbox>
            <div v-if="glb.authErrorMessage" class="text-error">{{glb.authErrorMessage}}</div>
            <div class="f5" v-if="formInfo.showDisclaimer">
                {{molang('dpp.mvtEmail.disclaimer')}}
            </div>
            <button class="btn primary regular" type="submit" >{{formInfo.buttonText}}</button>
            <div class="login-alternate">
                <hr>or<hr>
            </div>
            <mvtSocial :requestName="options.requestName" :trigger="options.trigger"/>
            <div class="f8 text-a lh-normal" v-html="molang('dpp.mvtEmail.movotoTerms1')">
            </div>
        </form>
    </div>
</template>
<script src="./mvt-checkemail.js"></script>

<style lang="scss">
     @import "./mvt-checkemail.scss";
</style>