<template>
    <div class="page-mvt-downloadapp">
        <section role="main" class="download-app-section">
            <div class="download-feature bg-brand">
                <div class="download-feature__content">
                    <h1 class="download-feature__title">Download the Movoto App</h1>
                    <div class="download-feature__description">The best place to learn &amp; analyze real estate quickly.</div>
                    <form class="download-feature__form" v-submit="submit" novalidate>
                        <div class="download-feature__input">
                            <input
                                type="tel"
                                name="phone"
                                v-model="phone"
                                v-inputformat:phone
                                autocomplete="tel-national"
                                v-validate="'required|phoneNumber'"
                                maxlength="12"
                                placeholder="Mobile Number"
                                class="inputMobile"
                            />
                            <button class="download-feature__btn" type="submit">Get the App</button>
                        </div>
                    </form>
                    <p class="download-feature__legalnote">U.S. mobile numbers only. Message and data rates apply. I agree to receive a one-time text message containing a link to download the Movoto app at the mobile number I provided above, and I confirm that the mobile number is mine.</p>
                    <div class="download-feature__appstores">
                        <a :href="appleStoreURL" target="_blank"
                            alt="Movoto Real Estate iOS App">
                        <img class="applestore" loading="lazy" alt="Download IOS APP" height="38" :src=" glb.cdnUrl + 'vimages/apple-store-black.svg'"/>
                        </a>
                        <a :href="googlePlayURL" target="_blank"
                            alt="Movoto Real Estate Android App">
                        <img class="googleplay" loading="lazy" alt="Download Android APP" height="38" :src=" glb.cdnUrl + 'vimages/google-play-black.svg'"/>
                        </a>
                    </div>
                </div>
                <div class="download-feature__img">
                    <img loading="lazy" alt="Movoto real estate Android app"
                    title="Movoto real estate Android app" :src="glb.cdnUrl+'vimages/download-app-screens.png'"/>
                </div>
            </div>
        </section>
    </div>
</template>
<script src="./mvt-downloadapp.js"></script>
<style lang="scss">
    @import './mvt-downloadapp.scss';
</style>

