import { mapGetters } from 'vuex';
import { nextTick } from 'vue';
import debounce from 'lodash/debounce';

export default {
    name: 'mvtDialog',
    inject: ['$eventBus'],
    props: {
        comp: {
            type: String,
            default: 'mvtDialog',
        },
    },
    computed: {
        ...mapGetters('glb', ['glb', 'getSplit']),
        componentName() {
            return this.comp;
        },
    },
    data: function () {
        return {
            isActive: false,
            currentName: '',
            currentTrigger: '',
            autoClose: true,
            redirectUrl: '',
        };
    },
    methods: {
        show: function (name, trigger, opt = {}) {
            // console.log('show dialog', trigger, name);
            setTimeout(() => {
                this.$eventBus.$once('close-dialog', (t) => {
                    this.close(t);
                });
            }, 10);
            if (opt.lite) {
                $('#body').addClass('dialog-lite');
            }
            if (opt && opt.autoClose === false) {
                this.autoClose = false;
                $('#body').removeClass('dialog-autoclose');
            } else {
                this.autoClose = true;
                $('#body').addClass('dialog-autoclose');
            }
            if (!this.isActive) {
                this.isActive = true;
                $.openOverlayer('dialog-active');
            }
            if (opt && opt.isMortgageRates) {
                $('#body').addClass('mortgage-rates-panel');
            } else {
                $('#body').removeClass('mortgage-rates-panel');
            }
            this.currentName = name;
            this.currentTrigger = trigger;
            this.redirectUrl = opt.redirectUrl || '';
            //trigger after dialog initaled
            nextTick(() => {
                this.checkSpace();
                this.$eventBus.$emit('eventbus-dialog-open', {
                    name: this.currentName,
                    trigger: this.currentTrigger,
                });
            });
            this.$emit('shown');
            if (this.autoClose) {
                $.router.add(this.$options.name);
            }
        },
        hide: function (trigger) {
            // console.log('hide dialog', trigger, this.currentName);
            $('#body').removeClass('dialog-lite');
            if (this.isActive) {
                this.isActive = false;
                $.closeOverlayer('dialog-active');
            }
            this.$emit('hidden');
            this.currentName = '';
            this.currentTrigger = '';
            this.$eventBus.$off('close-dialog', (t) => {
                this.close(t);
            });
        },
        close: function () {
            if (this.isActive) {
                let isSignUp = this.currentTrigger === 'showSignUpFromEmail';
                let trigger = this.currentTrigger;
                this.hide(trigger);
                if (this.autoClose) {
                    $.router.back(this.$options.name, true);
                }
                this.$eventBus.$emit('eventbus-dialog-close', {
                    name: this.currentName,
                    trigger,
                    componentName: this.componentName,
                });
                if (isSignUp) {
                    window.location.href = this.redirectUrl;
                }
            }
        },
        overlayClick: function () {
            if (this.autoClose) {
                this.close();
            }
        },
        checkSpace() {
            if (!this.isActive) {
                return;
            }
            var $dialog = $(this.$refs.dialog);
            let height = window.innerHeight - $(this.$refs.dialogBody).outerHeight();
            var isXs = this.glb.viewport.width <= this.glb.viewport.xsScreen[1];
            if (isXs) {
                $dialog.css('--dialog-space', Math.max(height, 50) + 'px');
            }
        },
    },
    mounted() {
        $(document).on('dom.resize.' + this.vnumber, debounce(this.checkSpace));
        $(document).on('dom.popstate.' + this.vnumber, (e, key) => {
            if (this.autoClose && this.isActive) {
                if (!key || key !== this.$options.name) {
                    this.hide(key);
                }
            }
        });
        $(document).on('dom.key.esc.' + this.vnumber, () => {
            if (this.autoClose) {
                this.close('shortcutESC');
            }
        });
    },
    beforeUnmount() {
        this.close();
        this.$eventBus.$off('close-dialog');
        $(document).off('dom.resize.' + this.vnumber);
        $(document).off('dom.key.esc.' + this.vnumber);
        $(document).off('dom.popstate.' + this.vnumber);
    },
};
