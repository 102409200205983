<template>
    <div class="share-link lh-normal flex space-arround middle">
        <a v-if="!exclude.includes('facebook')" href="https://www.facebook.com/MovotoRealEstate" aria-label="facebook" title="facebook">
            <i class="icon-facebook"></i>
        </a>
        <a v-if="!exclude.includes('twitter')" href="https://x.com/movoto?lang=en" aria-label="X" title="X">
            <i class="icon-x-social"></i>
        </a>
        <a v-if="!exclude.includes('instagram')" href="https://www.instagram.com/movotorealestate/" aria-label="instagram" title="instagram">
            <i class="icon-instagram"></i>
        </a>
        <a v-if="!exclude.includes('pinterest')" href="https://www.pinterest.com/movoto/" aria-label="pinterest" title="pinterest">
            <i class="icon-pinterest"></i>
        </a>
        <a v-if="!exclude.includes('linkedin')" href="https://www.linkedin.com/company/movoto/" aria-label="linkedin" title="linkedin">
            <i class="icon-linkedin-logo"></i>
        </a>
    </div>
</template>

<script>
export default {
    name: 'SocialLinks',
    props: {
      exclude: {
        type: Array,
        default: []
      },
    },
};
</script>
