<template>
    <div class="mvt-searchbox" :class="[{'mvt-searchbox--active': isVisible}, {changed: !noChanged}, `mvt-searchbox--size-${size}`, {'mvt-searchbox--mode-hero': heroMode}]">
        <div class="input right mvt-searchbox__input" >
            <button type="button" aria-label="Hide Search Results" class="mvt-searchbox__close" @click="hide"><i class="icon-angle-left"></i></button>
            <input type="text" ref="input" :placeholder="placeholder" autocomplete="off" @keydown.enter="onSubmit" />
            <button type="button" class="mvt-searchbox__submit" tabindex="0" role="button" aria-label="Search" @keydown.enter="onSubmit" @click="onSubmit"><i :class="getIcon" :title="getIcon === 'icon-search' ? 'Search' : null "></i></button>
        </div>
        <div class="mvt-searchbox__list">
            <template v-for="(autoSuggest,i) in searchSuggestions">
                <template v-if="autoSuggest.type!='google'">
                    <h3 v-if="autoSuggest.title" class="f7 text-uppercase">
                        {{ autoSuggest.title }}
                    </h3>
                    <div :class="{hover:index===i}">
                        <a href="javascript:void(0);" @click.prevent="action(autoSuggest)">
                            <i class="f4" :class="autoSuggest.icon"></i>
                            <div>
                                <b>{{ autoSuggest.text }}</b>
                                <div class="f7 text-capitalize" v-html="autoSuggest.subText"></div>
                            </div>
                        </a>
                    </div>
                </template>
                <h3 class="text-right" v-else="autoSuggest.google"><img height="16px" :src="glb.cdnUrl + 'images/desktop/powered_by_google_on_white@3x.png'" alt="powered by Google"></h3>
            </template>
        </div>
    </div>
</template>

<script src="./mvt-searchbox.js"></script>
