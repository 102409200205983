<template>
    <div class="authenticate-panel grid xs-grid-cols-1 gap-3">
        <div class="dialog-profile">
            <i class="circle  toggle  huge icon-user"></i>
        </div>
        <div class="text-center grid xs-grid-cols-1">
            <h3 class="f3 text-bold">Welcome Back!</h3>
            <div>{{getLoginMsg}}</div>
        </div>
        <form class="form form-default grid xs-grid-cols-1 gap-3">
            <mvtSocial :loginType="getLoginType" :trigger="options.trigger" :requestName="options.requestName"/>
            <div class="text-center" v-if="getLoginType === 'all'">
                <a  class="link" @click="switchComponent('mvtEmailLogin')">Sign in with email instead</a>
            </div>
        </form>
    </div>
</template>
<script src="./mvt-welcomeback.js"></script>