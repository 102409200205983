export default {
    "SignIn": "Registrarse",
    "Join": "Crear una cuenta",
    "ContactUs": "Contactanos",
    "savedHomes": "Tus Casas Guardadas",
    "MortgageCalculator": "Tasas Hipotecarias",
    "HomesforSale": "Casas en Venta",
    "HomesforRent": 'Casas en alquiler',
    "ApartmentsForRent": "Apartamentos en Renta",
    "OpenHouses": "Casas en Muestra",
    "VirtualTours": "Visitas virtuales",
    "RecentPriceReductions": "Casas Rebajadas",
    "SellYourHome": "Vende tu Casa",
    "NoObligationCashOffer": "Oferta en efectivo sin obligación",
    "GetaHomeValuation": "Cuánto Vale tu Casa",
    "BecomeaMovotoAgent": "Únete a Nuestra Red de Agentes",
    "Agent": "Guia de Agentes",
    "savedSearches": "Tus Búsquedas",
    "profile": "Administra tu perfil",
    "settings": "Ajustes",
    "HelpCenter": "Centro de ayuda",
    "meetAgentToday": "Reúnase con un agente de bienes raíces hoy",
    "call": "Llamada",
    "careers": "Carreras",
    "Faqs": "Preguntas frecuentes",
    "about": "Acerca de",
    "press": "Prensa",
    "feedback": "Feedback",
    "sitemap": "Mapa",
    "rentalsitemap": "Mapa para Rentar",
    "blog": "Movoto Blog",
    "guide": "Movoto Guía",
    "CanadaSite": "Canada",
    "send": "Enviar",
    "getApp": "Obtener la App de Movoto"
}