<template>
    <div class="authenticate-panel grid xs-grid-cols-1 gap-3" >
        <div class="text-center">
            <h3 class="f3 text-bold">Forgot Password?</h3>
            <div>Enter your email address and we’ll send you a link to reset your password.</div>
        </div>
        <form class="form form-default grid xs-grid-cols-1 gap-3" :class="formState" v-submit="submit" novalidate>
            <mvtTextbox>
                <div class="input">
                    <label>Email</label>
                    <input type="email" name="email" v-model="username" v-validate="'required|email'" />
                </div>
            </mvtTextbox>
            <div v-if="errorMessage" class="text-error">{{errorMessage}}</div>
            <button class="btn primary large" type="submit" >Send</button>
        </form>
        <div class="dialog-desc">
            Know your password?
            <a  class="link"  @click="switchComponent('mvtEmailLogin')">Sign In</a>
        </div>
    </div>
</template>
<script src="./mvt-forgetpassword.js"></script>
