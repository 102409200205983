import mvtTextbox from '@/common/components/mvttextbox/mvt-textbox.vue';
import mvtSocial from '@/common/components/mvtsocial/mvt-social.vue';
import { mapActions, mapGetters } from 'vuex';
import SplitTreatments from '@/common/components/split/SplitTreatments.vue';

export default {
    name: 'mvtLogin',
    components: {
        mvtTextbox,
        mvtSocial,
        SplitTreatments,
    },
    props: {
        options: {
            type: Object,
            default: {
                trigger: '',
                requestName: '',
                title: '',
                subTitle: '',
                profile: '',
                isForcedSignup: false,
            },
        },
    },
    inject: ['$eventBus'],
    data() {
        return {
            email: '',
            showOnlyEmail: false,
            phone: (this.options && this.options.phone) || '',
            name: (this.options && this.options.name) || '',
        };
    },
    mounted() {
        this.showOnlyEmail = this.isAgentTouringAgreement || this.isTriggerByPopSignIn || this.isForcedSignup; //show only email field in signinpop initially
        $(this.$el).on('focus', 'input', () => {
            this.glb.authErrorMessage = '';
            if (this.isTriggerByPopSignIn) {
                this.showOnlyEmail = false;
            }
        });
        this.setLoginEmail();
    },
    computed: {
        ...mapGetters('glb', ['glb', 'getErrorHtml', 'getSplit']),
        isTriggerByAuth() {
            return this.options.trigger !== 'mvtHeader' && this.options.trigger !== 'mvtFooter';
        },
        getProfile() {
            return this.options.profile || 'icon-user';
        },
        getTitle() {
            return this.options.title || 'Welcome to Movoto';
        },

        getSubTitle() {
            return this.options.subTitle || 'Join by creating an account.';
        },
        isTriggerByPopSignIn() {
            return this.options.trigger === 'mvtPopSignin';
        },
        isForcedSignup() {
            return this.options.isForcedSignup;
        },
        isAgentTouringAgreement() {
            const returnUrl = new URLSearchParams(window?.location?.search).get('returnurl');
            return returnUrl && returnUrl.includes('/my-movoto/myagent/') && returnUrl.includes('agentId=') && returnUrl.includes('customerId=') && returnUrl.includes('customerEmail=');
        },
        agentTouringAgreementEmail() {
            if (this.isAgentTouringAgreement) {
                const returnUrl = new URL(new URLSearchParams(window?.location?.search).get('returnurl'));
                const email = new URLSearchParams(returnUrl.search).get('customerEmail');
                return email;
            }
            return null;
        },
        ctaText() {
            return this.phone ? 'Connect' : 'Continue';
        },
    },
    methods: {
        ...mapActions('glb', ['login', 'checkUserByEmailId']),
        setLoginEmail() {
            this.email = this.isAgentTouringAgreement ? this.agentTouringAgreementEmail : (this.options && this.options.email) || '';
        },
        switchComponent(name) {
            this.$emit('switchComponent', name);
        },
        submit() {
            if (this.isForcedSignup) {
                this.options.requestName = 'mainForce';
            }
            this.$eventBus.$emit('eventbus-submit-signup', { email: this.email, requestName: this.options.requestName || '', phone: this.phone || '', name: this.name || '' });
            if (this.isTriggerByPopSignIn) {
                this.$eventBus.$emit('eventbus-closePopupSignIn');
            }
        },
    },
};
