export default {
    SignIn: 'Sign In',
    Join: 'Create Account',
    ContactUs: 'Contact Us',
    savedHomes: 'Favorite Homes',
    MortgageCalculator: 'Mortgage Calculator',
    HomesforSale: 'Homes for Sale',
    HomesforRent: 'Homes for Rent',
    ApartmentsForRent: 'Apartments for Rent',
    OpenHouses: 'Open Houses',
    VirtualTours: 'Virtual Tours',
    RecentlyReduced: 'Recently Reduced',
    SellYourHome: 'Sell Your Home',
    NoObligationCashOffer: 'No Obligation Cash Offer',
    GetaHomeValuation: 'Get a Home Valuation',
    BecomeaMovotoAgent: 'Join Agent Network',
    PartnerWitUs: 'Partner With Us',
    Agent: 'Agent Directory',
    savedSearches: 'Saved Searches',
    profile: 'Manage Your Profile',
    settings: 'Settings',
    HelpCenter: 'Help Center',
    meetAgentToday: 'Meet with a real estate agent today',
    call: 'Call',
    careers: 'Careers',
    Faqs: 'FAQ',
    about: 'About',
    press: 'Press',
    feedback: 'Feedback',
    sitemap: 'Sitemap',
    rentalsitemap: 'Rental Sitemap',
    blog: 'Movoto Blog',
    guide: 'Movoto Guide',
    CanadaSite: 'Canada',
    send: 'Send',
    getApp: 'Get the Movoto App',
};