import { mapGetters } from 'vuex';

export default {
    name: 'dppAppBanner',
    inject: ['$eventBus'],
    computed: {
        ...mapGetters('glb', ['glb']),
        ...mapGetters('glb', ['glb', 'getSplit']),
        isAppInstalled() {
            return this.appInstalled;
        },
    },
    data() {
        return {
            appInstalled: true,
        };
    },
    methods: {
        openAppInstall() {
            this.$eventBus.$emit('ga', { ec: 'capp', ea: 'app_banner_click', el: 'app_bannerOpen' });
            if (this.glb.OS === 'iOS') {
                window.open('https://apps.apple.com/app/apple-store/id537795977?pt=469170&ct=movoto.com%20banner&mt=8');
            } else {
                window.open('https://play.google.com/store/apps/details?id=com.movoto.movoto&utm_campaign=movotobanner');
            }
        },
        show() {
            $('#body').addClass('show-appbanner');
        },
        hide() {
            $('#body').removeClass('show-appbanner');
            $.setStorage('appBannerClosed', true);
        },
    },
    mounted() {
        if (!$.getStorage('appBannerClosed')) {
            this.show();
        }
        this.$eventBus.$on('eventbus-app-banner', this.show);
    },
};
