<template>
    <div class="mvt-map-static">
        <img ref="img" :src="placeholderUrl" loading="lazy" :alt="alt" v-once>
        <slot></slot>
    </div>
</template>
<script src="./mvt-mapstatic.js"></script>
<style lang="scss">
@import "./mvt-mapstatic.scss";
</style>
