<template>
    <section>
        <div class="grid fixed" :class="theme !== 'small' ? 'sm-grid-cols-2' : 'xs-grid-cols-1'">
            <mvtShareLink/>
            <div class="flex space-around middle" style="font-size: 0;">
                <a href="https://apps.apple.com/app/apple-store/id537795977?pt=469170&ct=movoto.com%20footer&mt=8" target="_blank" title="Movoto Real Estate iOS App">
                    <img class="applestore" style="width:103px; height:35px" loading="lazy" alt="Download IOS APP"  :src=" glb.cdnUrl + 'images/desktop/appdownload/applestore.svg'"/>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.movoto.movoto&utm_campaign=movotofooter" target="_blank" title="Movoto Real Estate Android App">
                    <img class="googleplay" style="width:104px; height:35px" loading="lazy" alt="Download Android APP" :src=" glb.cdnUrl + 'images/desktop/appdownload/googleplay.svg'"/>
                </a>
            </div>
        </div>
    </section>
</template>

<script src="./mvt-footerlinks.js"></script>
