import { mapGetters } from 'vuex';
export default {
    name: 'mvtCompliance',
    inject: ['$eventBus'],
    computed: {
        ...mapGetters('glb', ['glb']),
        hasRentalDisclaimer() {
            if (this.glb.rentals && this.glb.pageType && ['vhome', 'vmapsearch', 'vdpp'].includes(this.glb.pageType)) {
                return true;
            }
            return false;
        },
        getYear() {
            return new Date(this.glb.date).getFullYear();
        },
    },
    methods: {
        ...mapGetters('glb', ['getComplianceMethod']),
        getDisclaimer() {
            const compliance = this.getComplianceMethod()();
            if (this.glb.hideMlsInfo || !compliance || !compliance.length) {
                return [];
            }
            return compliance.reduce(function (prev, next) {
                var item = next.desc;
                if (item) {
                    prev.push(item);
                }
                return prev;
            }, []);
        },
        getMLSInfo() {
            const compliance = this.getComplianceMethod()();
            if (this.glb.hideMlsInfo || !compliance || !compliance.length) {
                return '';
            }
            const mlsId = compliance[0].id;
            let mlsName = compliance[0].name;
            if (compliance[0].rule) {
                const logo =
                    compliance[0].rule.indexOf('showMLSLogo') >= 0 && mlsId ? `<img loading="lazy" class="img-mlslogo" alt="mls logo" src="${this.glb.cdnUrl + 'images/desktop/logo/logo_' + mlsId + '.png'}">` : '';
                const mlsGridLogo =
                    compliance[0].rule.indexOf('showMLSGridLogo') >= 0 && mlsId ? `<img loading="lazy" class="img-mlslogo" alt="mls logo" src="${this.glb.cdnUrl + 'images/desktop/logo/logo_mlsgrid.png'}">` : '';

                return `${this.molang('search.compliance.generic')} ${logo} ${mlsGridLogo} ${mlsName}.`;
            }
        },
        openAccessibilityHotlead() {
            var hotleadOpt = {
                hotleadTitle: this.molang('common.ContactUs'),
                hotleadType: this.glb.isMobile ? 'ContactMovotoMobile' : 'ContactMovotoDesktop',
                buttonText: this.molang('dpp.hotleadForm.send'),
                showComment: true,
            };
            this.$eventBus.$emit('dialog-center', { name: 'mvtHotleadForm', trigger: this.$options.name, opt: hotleadOpt });
        },
    },
};
