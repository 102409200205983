import { mapGetters, mapActions } from 'vuex';

const isSSR = typeof window === 'undefined';

export default {
    name: 'mvtDownloadApp',
    inject: ['$eventBus'],
    props: ['appUTM'],
    data() {
        return {
            phone: '',
        };
    },
    computed: {
        ...mapGetters('glb', ['glb']),
        googlePlayURL() {
            let url = 'https://play.google.com/store/apps/details?id=com.movoto.movoto';
            return this.getAppUTM(url, true);
        },
        appleStoreURL() {
            let url = 'https://apps.apple.com/app/apple-store/id537795977?pt=4691700&ct=movoto.com/mobile&mt=8';
            return this.getAppUTM(url);
        },
    },
    methods: {
        // Add one actions method in glb for sms sending(sendAppLink)
        ...mapActions('glb', ['sentAppMessage']),
        getAppUTM(url, isUtmCampaign) {
            if (isUtmCampaign) {
                this.appUTM ? (url += ``) : (url += `&utm_campaign=buttonmobilepage`);
            }
            const queryParam = !isSSR && window.location.search && window.location.search.substring(1);
            url = queryParam ? url + '&' + queryParam : url;
            return url;
        },
        submit() {
            let errorHandle = (status) => {
                this.$eventBus.$emit('dialog-center', {
                    name: 'mvtConfirm',
                    opt: {
                        title: 'ERROR',
                        message: status && status.msg ? status.msg : 'Error - Could not send message',
                        //message: 'Error - Could not send message'
                    },
                    trigger: this.$options.name,
                });
            };
            this.sentAppMessage({ phone: this.phone })
                .then((resp) => {
                    let status = resp && resp.status;
                    if (status && status.code === 0) {
                        this.$eventBus.$emit('toast-center', {
                            title: 'Text Sent',
                            message: 'Check your text messages and tap on the link to download the Movoto app.',
                            iconClass: 'icon-check-circle-filled',
                        });
                        this.phone = '';
                    } else {
                        errorHandle(status);
                    }
                })
                .catch(errorHandle);
        },
    },
};
