import {mapGetters} from 'vuex';
import { getGa4Trigger } from '../../../../widget/spec/ga4helper';

export default {
    name: 'mvtSocial',
    props: {
        loginType: {
            type: String,
            default: 'all'
        },
        textgoogle: {
            type: String,
            default: 'Continue with Google'
        },
        textfacebook: {
            type: String,
            default: 'Continue with Facebook'
        },
        textapple: {
            type: String,
            default: 'Continue with Apple'
        },
        trigger: {
            type: String,
            default: ''
        },
        requestName: {
            type: String,
            default: '',
        },
    },
    inject: ['$eventBus'],
    computed:{
        ...mapGetters('glb', ['glb', 'getSplit'])
    },
    methods: {
        facebookLogin() {
            this.$eventBus.$emit('eventbus-login-facebook');
        },
        googleLogin() {
            this.$eventBus.$emit('ga', { ec: 'login', en: 'click', el: 'signin_google'});
        },
        appleLogin() {
            this.$eventBus.$emit('eventbus-login-apple', { trigger: this.trigger || this.$options.name, requestName: this.requestName });
        }
    },
    mounted(){
        var that = this;
        $.loadGoogleOneTapSignin().then(function  ()  {
            var redirectUrl = (location.href.indexOf('#mvtDialog') > -1) ? location.href.split('#mvtDialog')[0] : location.href;
            $.setStorage('googleRedirectUrl', redirectUrl);
            $.setStorage('googleLoginTrigger', that.trigger);
            $.setStorage('googleLoginRequestName', that.requestName);
            google.accounts.id.initialize({
                client_id: that.glb.googleLoginClientId,
                ux_mode: 'redirect',
                login_uri: that.glb.appUrl + 'logingoogle/',
                use_fedcm_for_prompt: true,
            });

            const signinDivElement = document.getElementById('signinDiv');
            if(!signinDivElement){
                return;
            }

            google.accounts.id.renderButton(signinDivElement, {
                type: 'standard',
                theme: 'outline',
                size: 'large',
                shape: 'pill',
                text: 'continue_with',
                logo_alignment: 'left',
                width: '350px',
                click_listener: that.googleLogin
            });
        });
    }
};
