<template>
    <header id="header" :class="['header', `header--theme-${theme}`, {'header--sticky': sticky}]" v-signal v-thirdpartload="glb.enableThirdPart" v-xlead="state" :data-vssr="glb.isSSR">
        <div class="header-bar">
            <a href="javascript:;" role="button" @click="toggle" title="Movoto Logo" class="header-icon hidden-md hidden-lg" aria-label="Expand Menu"></a>
            <a :href="glb.appUrl" title="Movoto Logo" class="header-logo hidden-xs hidden-sm" aria-label="Movoto Home"></a>
            <div class="header-bar-search" v-if="!disableSearch">
                 <slot name="searchbox"></slot>
            </div>
            <slot name="button"></slot>
        </div>
        <div class="header-nav">
            <ul class="header-menu-list">
                <a :href="glb.appUrl" v-if="expand" title="Movoto Logo" class="header-menu-logo">
                    <img :src="glb.cdnUrl + 'vimages/movoto-logo.svg'" alt="Movoto Logo">
                </a>
                <li class="right setting list" v-if="glb.user.id">
                    <button class="sharp" >
                        <span class=" small circle active" :class="theme === 'brand' ? 'main' : 'gray'">
                            <template v-if="getUserShortName">{{getUserShortName}}</template>
                            <i class="icon-user" v-else></i>
                        </span>
                        <span class="hidden-lg hidden-md name">{{getUserName}}</span>
                    </button>
                    <ul class="user-menu" v-if="glb.user.id && loginMenu.length">
                        <li>
                            <a :href="glb.appUrl + 'my-movoto/myagent/'">
                                {{ linkLabels.agentHeader }}
                            </a>
                        </li>
                        <li>
                            <a :href="glb.appUrl + 'my-movoto/favorites/'">
                                {{ linkLabels.homeHeader }}
                                <span v-if="getSavedHomesCount > 1">({{ getSavedHomesCount }})</span>
                            </a>
                        </li>
                        <li>
                            <a :href="glb.appUrl + 'my-movoto/saved-searches/'">
                                {{ linkLabels.searchesHeader }}
                                <span v-if="getSavedSearchesCount > 1">({{ getSavedSearchesCount }})</span>
                            </a>
                        </li>
                        <li>
                            <a :href="glb.appUrl + 'homeowner/'">{{ linkLabels.ownerHeader }}</a>
                            <div class="claim-home" v-if="!getClaimedProperties">
                                <div class="sub">Understand your home’s value, uncover hidden savings, and build wealth.</div>
                                <a class="btn primary small" :href="glb.appUrl + 'homeowner/'">Add a Home</a>
                            </div>
                            <div class="claimed-homes" v-else>
                                <template v-for="(item, i) in getClaimedProperties">
                                    <a :key="i" :href="glb.appUrl + 'my-movoto/owned-homes/' + item.propertyId" v-if="i<2">
                                        <img loading="lazy" v-if="item.photoCount > 0" :alt="item.geo.address"  class="img" :src="item.tnImgPath" />
                                        <slot v-else name="mapstatic" v-bind="item.geo"></slot>
                                        {{item.geo.address}}
                                    </a>
                                </template>
                                <span v-if="getClaimedProperties.length>2">+ {{getClaimedProperties.length - 2}} more</span>
                            </div>
                        </li>
                        <li v-for="link in loginMenu">
                            <a v-if="link.href" :href="link.href" :rel="link.rel" >{{ link.text }}</a>
                            <a v-else-if="link.event" role="button"  @click="sendEvent(link.event)">
                                {{ link.text }}
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="right" v-if="!glb.user.id" :class="{'sign-in-info': !glb.user.id}">
                    <div class="login-signup" >
                        <div class="hidden-xs hidden-sm flex">
                            <a @click="showLogin()" href="javascript:;" role="button">Sign In</a>
                            <button class="btn secondary regular" type="button" aria-label="Join" @click="showSignup()">Join</button>
                        </div>
                        <div class="hidden-lg hidden-md sign-in-btn">
                            <button type="button" aria-label="Join" class="btn primary small" @click="showSignup()">Join</button>
                            <button type="button" aria-label="Sign In" class="btn secondary small" @click="showLogin()">Sign In</button>
                        </div>
                    </div>
                </li>
                <li class="hidden-md hidden-lg">
                    <a :href="glb.appUrl" v-text="linkLabels.headerHome"></a>
               </li>
                <li v-for="item in getMenu" :class="{'list': item.list, 'rent': item.class == 'rent', 'agent': item.class == 'agent'}">
                    <button @click="goFirst(item)" :title="item.title || null">{{ item.text }}</button>
                    <ul v-if="item.list && item.list.length">
                        <li v-for="link in item.list.filter(listItem => !isMounted ? listItem.ssr : true)">
                            <a :href="link.href" :rel="link.rel">{{ link.text }}</a>
                        </li>
                    </ul>
                </li>
                <template v-if="renderPersonalizedCounts">
                    <li v-show="isNotification">
                        <a :href="glb.appUrl+ 'notification/'" rel="nofollow" title="Notification">
                        <i class="icon-bell f3"></i>
                            <span v-if="getNotificationCount" class="menu-count bell-active notification"></span>
                        </a>
                    </li>
                    <li v-show="!isNotification && getFeed && getFeed.length > 0">
                        <a :href="glb.appUrl+ 'feed/'" rel="nofollow" title="Feed" >
                            <i class="icon-bell f3"></i>
                            <span v-if="getFeed.length" class="menu-count bell-active"></span>
                        </a>
                    </li>
                </template>
                <li class="hidden-md">
                    <a :href="('tel:' + getHeaderPhoneNumber)">{{formattedPhoneNumber}}</a>
                </li>
                <li class="header-agent-connect hidden-md hidden-lg">
                    <a aria-label="Connect with an agent" role="button" @click="openHotlead" class="btn tertiary small">Connect with an agent</a>
                </li>
           </ul>
        </div>
        <a id="cmlink1" style="display: none" href="javascript:;" role="button"></a>
    </header>
</template>

<script>
export default {
    name: 'MvtHeaderPresentational',
    props: {
        showLogin: Function,
        showSignup: Function,
        toggle: Function,
        getHeaderPhoneNumber: String,
        getUserShortName: String,
        getUserName: String,
        getSavedHomesCount: Number,
        getSavedSearchesCount: Number,
        getClaimedProperties: Number,
        getNotificationCount: Number,
        getFeed: Array,
        isNotification: Boolean,
        getMenu: Array,
        item: Array,
        loginMenu: Object,
        link: Array,
        isMounted: Boolean,
        renderPersonalizedCounts: Boolean,
        expand: Boolean,
        formattedPhoneNumber: String,
        searchInput: String,
        lazyload: Object,
        state: String,
        sticky: Boolean,
        disableSearch: Boolean,
        linkLabels: Object,
        glb: Object,
        goFirst: Function,
        saveSearch: Function,
        sendEvent: Function,
        openHotlead: Function,
        theme: {
            type: String,
            required: false,
            default: 'default',
            validator(value) {
                return ['default', 'transparent', 'brand'].includes(value);
            }
        },
    }
};
</script>
