//Put other components below
<template>
<component :is="mvtLinter" />
<mvtAppBanner v-if="showAppBanner && glb.user && glb.user.id"/>
    <mvtHeaderPresentationalNew v-if="getSplit('movoto-new-global-header-CW-2423')"
        :formattedPhoneNumber="$filters.formatPhoneNumber(getHeaderPhoneNumber)"
        :glb="glb"
        :showLogin="showLogin"
        :showSignup="showSignup"
        :toggle="_toggle"
        :disableSearch="disableSearch"
        :loginMenu="loginMenu"
        :isMounted= "isMounted"
        :getUserShortName= "getUserShortName"
        :getUserName= "getUserName"
        :getSavedHomesCount= "getSavedHomesCount"
        :getSavedSearchesCount= "getSavedSearchesCount"
        :getClaimedProperties= "getClaimedProperties"
        :getMenu= "getMenu"
        :getNotificationCount= "getNotificationCount"
        :getFeed= "getFeed"
        :saveSearch="saveSearch"
        :isNotification= "isNotification"
        :searchInput="searchInput"
        :sticky="sticky"
        :state="geo.state"
        :goFirst="goFirst"
        :sendEvent="sendEvent"
        :openHotlead="openHotlead"
        :getHeaderPhoneNumber="getHeaderPhoneNumber"
        :expand="expand"
        :renderPersonalizedCounts="renderPersonalizedCounts"
        :theme="theme"
        :linkLabels="{
                agentHeader: molang('header.myAgent'),
                homeHeader: molang('header.savedHomes'),
                searchesHeader: molang('header.savedSearches'),
                ownerHeader: molang('header.Homeowner'),
                headerHome: molang('header.Home'),
                headerSavedHomes: molang('header.savedHomes'),
                savedSearchesHeader: molang('header.savedSearches'),
            }"
        >
        <template v-slot:searchbox>
            <mvtSearchbox class="fullscreen" id="textboxGeo" ref="autocomplete" :placeholder="'Search city, ZIP, school, address, neighborhood'" v-model="searchInfo" />
        </template>
        <template v-slot:button>
            <div class="hidden-lg hidden-md" id="headerSaveSearch">
                <button type="button" class="medium btn alternative" @click="saveSearch">Save search</button>
            </div>
        </template>
        <template  v-slot:mapstatic="{lat, lng, fullAddress}">
            <mvtMapStatic class="circle img" :cacheFirst="true" :lat="lat" :lng="lng" type="streetview" streetViewDefautType="satellite" :address="fullAddress"></mvtMapStatic>
        </template>
    </mvtHeaderPresentationalNew>
    <mvtHeaderPresentational v-else
        :formattedPhoneNumber="$filters.formatPhoneNumber(getHeaderPhoneNumber)"
        :glb="glb"
        :showLogin="showLogin"
        :showSignup="showSignup"
        :toggle="_toggle"
        :disableSearch="disableSearch"
        :loginMenu="loginMenu"
        :isMounted= "isMounted"
        :getUserShortName= "getUserShortName"
        :getUserName= "getUserName"
        :getSavedHomesCount= "getSavedHomesCount"
        :getSavedSearchesCount= "getSavedSearchesCount"
        :getClaimedProperties= "getClaimedProperties"
        :getMenu= "getMenu"
        :getNotificationCount= "getNotificationCount"
        :getFeed= "getFeed"
        :saveSearch="saveSearch"
        :isNotification= "isNotification"
        :searchInput="searchInput"
        :sticky="sticky"
        :state="geo.state"
        :goFirst="goFirst"
        :sendEvent="sendEvent"
        :openHotlead="openHotlead"
        :getHeaderPhoneNumber="getHeaderPhoneNumber"
        :expand="expand"
        :renderPersonalizedCounts="renderPersonalizedCounts"
        :theme="theme"
        :linkLabels="{
                agentHeader: molang('header.myAgent'),
                homeHeader: molang('header.savedHomes'),
                searchesHeader: molang('header.savedSearches'),
                ownerHeader: molang('header.Homeowner'),
                headerHome: molang('header.Home'),
                headerSavedHomes: molang('header.savedHomes'),
                savedSearchesHeader: molang('header.savedSearches'),
            }"
        >
        <template v-slot:searchbox>
            <mvtSearchbox class="fullscreen" id="textboxGeo" ref="autocomplete" :placeholder="'City, ZIP, School, Address, Neighborhood'" v-model="searchInfo" />
        </template>
        <template v-slot:button>
            <div class="hidden-lg hidden-md" id="headerSaveSearch">
                <button type="button" class="medium btn alternative" @click="saveSearch">Save search</button>
            </div>
        </template>
        <template  v-slot:mapstatic="{lat, lng, fullAddress}">
            <mvtMapStatic class="circle img" :cacheFirst="true" :lat="lat" :lng="lng" type="streetview" streetViewDefautType="satellite" :address="fullAddress"></mvtMapStatic>
        </template>
    </mvtHeaderPresentational>
</template>

<script src="./mvt-header.js"></script>
