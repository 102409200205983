import mvtLogin from '@/common/components/mvtauthenticate/mvt-login.vue';
import mvtSignUp from '@/common/components/mvtauthenticate/mvt-signup.vue';
import mvtForgetPassword from '@/common/components/mvtauthenticate/mvt-forgetpassword.vue';
import mvtWelcomeBack from '@/common/components/mvtauthenticate/mvt-welcomeback.vue';
import mvtCheckEmail from '@/common/components/mvtauthenticate/mvt-checkemail.vue';
import mvtEmailLogin from '@/common/components/mvtauthenticate/mvt-emaillogin.vue';
import { mapMutations } from 'vuex';
export default {
    name: 'mvtAuthenticate',
    root: true,
    inject: ['$eventBus'],
    props: {
        options: {
            type: Object,
            default: {
                type: 'mvtLogin',
                redirectUrl: '',
                trigger: '',
                title: '',
                subTitle: '',
                buttonText: '',
                requestName: '',
                isForcedSignup: false,
            },
        },
    },
    data() {
        return {
            componentName: 'mvtLogin',
            redirectUrl: '',
        };
    },
    watch: {
        options: {
            handler: function (val) {
                this.componentName = val.type;
                if (val.redirectUrl) {
                    this.redirectUrl = val.redirectUrl;
                }
            },
            deep: true,
        },
    },
    mounted() {
        if (this.options) {
            this.componentName = this.options.type;
            if (this.options.redirectUrl) {
                this.redirectUrl = this.options.redirectUrl;
            }
        }
    },
    methods: {
        ...mapMutations('glb', ['updateLoginToDoList']),
        updateComponentName(name) {
            this.componentName = name;
            setTimeout(() => {
                $(document).trigger('dom.resize');
            }, 1);
        },
    },
    beforeUnmount() {
        //clean todo list when close authenticate;
        this.updateLoginToDoList(null);
    },
    components: {
        mvtLogin,
        mvtSignUp,
        mvtForgetPassword,
        mvtWelcomeBack,
        mvtCheckEmail,
        mvtEmailLogin,
    },
};
