import {mapGetters} from 'vuex';
import mvtShareLink from '@/common/components/mvtsharelink/mvt-sharelink.vue';
export default {
    name: 'mvtFooterLinks',
    root: true,
    inject: ['$eventBus'],
    props: ['theme'],
    components: {
        mvtShareLink
    },
    computed: {
        ...mapGetters('glb', ['glb', 'getPhoneNumber'])
    },
    methods: {
        openContact: function() {
            var hotleadOpt = {
                hotleadTitle: this.molang('common.ContactUs'),
                hotleadType: this.glb.isMobile ? 'ContactMovotoMobile' : 'ContactMovotoDesktop',
                buttonText: this.molang('footer.send'),
                showComment: true,
                hideVeteran: true,
                profileUrl: 'icon-envelope',
            };
            this.$eventBus.$emit('dialog-center', {name: 'mvtHotleadForm', trigger: this.$options.name, opt: hotleadOpt});
        }
    },
    mounted() {
        this.$eventBus.$on('dialog-open-contactus', this.openContact);
    },
    beforeUnmount(){
        this.$eventBus.$off('dialog-open-contactus');
    }
};
