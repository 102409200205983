import format from '@/common/utilities/format';
import {mapGetters} from 'vuex';

const propertyTypes = {
    singleFamily: 'single-family',
    condos: 'condos',
    multiFamily: 'multi-family',
    land: 'land',
    mobile: 'mobile',
    other: 'other'
};

const feedPropertyTypes = {
    singleFamily: 'SINGLE_FAMILY_HOUSE',
    condos: 'CONDO',
    multiFamily: 'MULTI_FAMILY',
    land: 'LAND',
    mobile: 'MOBILE',
    other: 'OTHER'
}

function formatMinLabel(value, suffix = '') {
    if (!value) {
        return null;
    }
    return `${value}+ ${suffix}`;
}

function formatMinMaxLabel(values, prefix = '', formatter) {
    const {min, max} = values;
    if ((min === '0' || min === '') && (max === '0' || max === '')) {
        return null;
    }

    if (min && max == '0') {
        return formatter ? `${prefix} ${formatter(min)}+` : `${prefix} ${min}+`;
    }
    if (min && max && min === max) {
        return formatter ? `${prefix} ${formatter(min)}` : `${prefix} ${min}`;
    }
    return formatter ? `${prefix} ${formatter(min)} - ${formatter(max)}` : `${prefix} ${min} - ${max}`;
}

function getLotSizeUnit(value) {
   if(!value){
        return '0';
   }
   if(value < 43560){
       return value + 'Sqft';
   }
   if(value >= 43560){
       return Math.round(value / 43560) + ' Acre';
   }
}

function formatPrice(value) {
    const priceNumber = parseInt(value);
    if (priceNumber === 0 || isNaN(priceNumber)) {return '0';}
    return '$' + format.friendlyPrice(priceNumber);
}

function formatArea(value) {
    const area = parseInt(value);
    if (area === 0 || isNaN(area)) {return '0';}
    return format.formatNumberByComma(area) + ' Sqft';
}

export default {
    computed: {
        ...mapGetters('glb', ['getSplit'])
    },
    methods: {
        checkPropertyType(value) {
            return this.msp.filter.propertyType.includes(value);
        },
        getFilters() {
            let filterState = this.msp.filter;
            let list = [{
                    keys: ['minPrice', 'maxPrice'],
                    isActive: () => filterState.minPrice || filterState.maxPrice,
                    getLabel: () => {
                        const values = {min: filterState.minPrice, max: filterState.maxPrice};
                        return formatMinMaxLabel(values, '', formatPrice);
                    }
                },  {
                    keys: ['propertyType'],
                    isArray: true,
                    value: propertyTypes.singleFamily,
                    isActive: () => this.checkPropertyType(propertyTypes.singleFamily),
                    getLabel: () => this.molang('search.moreFilter.houses')
                }, {
                    keys: ['propertyType'],
                    isArray: true,
                    value: propertyTypes.condos,
                    isActive: () => this.checkPropertyType(propertyTypes.condos),
                    getLabel: () => this.molang('search.moreFilter.condo')
                }, {
                    keys: ['propertyType'],
                    isArray: true,
                    value: propertyTypes.multiFamily,
                    isActive: () => this.checkPropertyType(propertyTypes.multiFamily),
                    getLabel: () => this.molang('search.moreFilter.multiFamily')
                }, {
                    keys: ['propertyType'],
                    isArray: true,
                    value: propertyTypes.land,
                    isActive: () => this.checkPropertyType(propertyTypes.land),
                    getLabel: () => this.molang('search.moreFilter.lotLand')
                }, {
                    keys: ['propertyType'],
                    isArray: true,
                    value: propertyTypes.mobile,
                    isActive: () => this.checkPropertyType(propertyTypes.mobile),
                    getLabel: () => this.molang('search.moreFilter.mobile')
                }, {
                    keys: ['propertyType'],
                    isArray: true,
                    value: propertyTypes.other,
                    isActive: () => this.checkPropertyType(propertyTypes.other),
                    getLabel: () => this.molang('search.moreFilter.other')
                }, {
                    keys: ['minHouseSize', 'maxHouseSize'],
                    isActive: () => filterState.minHouseSize || filterState.maxHouseSize,
                    getLabel() {
                        const values = {min: filterState.minHouseSize, max: filterState.maxHouseSize};
                        return formatMinMaxLabel(values, 'Home Size', formatArea);
                    }
                }, {
                    keys: ['minLotSize', 'maxLotSize'],
                    isActive: () => filterState.minLotSize || filterState.maxLotSize,
                    getLabel() {
                        const values = {min: filterState.minLotSize, max: filterState.maxLotSize};
                        return formatMinMaxLabel(values, 'Lot Size', getLotSizeUnit);
                    }
                }, {
                    keys: ['minYearBuild', 'maxYearBuild'],
                    isActive: () => filterState.minYearBuild || filterState.maxYearBuild,
                    getLabel() {
                        const values = {min: filterState.minYearBuild, max: filterState.maxYearBuild};
                        return formatMinMaxLabel(values, 'Years Built');
                    }
                }, {
                    keys: ['minDOM', 'maxDOM'],
                    isActive: () => filterState.minDOM || filterState.maxDOM,
                    getLabel() {
                        const values = {min: filterState.minDOM, max: filterState.maxDOM};
                        return formatMinMaxLabel(values, 'Days on Movoto');
                    }
                }, {
                    keys: ['minHoa', 'maxHoa'],
                    isActive: () => ((filterState.minHoa != -1) || (filterState.maxHoa != -1)),
                    getLabel() {
                        const values = {
                            min: (filterState.minHoa == -1 ? 0 : filterState.minHoa),
                            max: (filterState.maxHoa == -1 ? 0 : filterState.maxHoa)
                        };
                        return filterState.maxHoa == 0 ? 'No HOA' : formatMinMaxLabel(values, 'HOA Fees');
                    }
                }, {
                    keys: ['garage'],
                    isActive: () => filterState.garage,
                    getLabel: () => `${filterState.garage}+ Garage`
                }, {
                    keys: ['propertyCriteriaOpenHouse'],
                    isActive: () => filterState.propertyCriteriaOpenHouse,
                    getLabel: () => this.molang('search.moreFilter.openHouses')
                }, {
                    keys: ['propertyCriteriaForeclosed'],
                    isActive: () => filterState.propertyCriteriaForeclosed,
                    getLabel: () => this.molang('search.moreFilter.foreclosure')
                }, {
                    keys: ['updateNew'],
                    isActive: () => filterState.updateNew,
                    getLabel: () => this.molang('search.moreFilter.newInLast7Days')
                }, {
                    keys: ['updatePriceReduced'],
                    isActive: () => filterState.updatePriceReduced,
                    getLabel: () => this.molang('search.moreFilter.priceReduced')
                }, {
                    keys: ['pool'],
                    isActive: () => filterState.pool,
                    getLabel: () => this.molang('search.moreFilter.pool')
                }, {
                    keys: ['fixerupper'],
                    isActive: () => filterState.fixerupper,
                    getLabel: () => this.molang('search.moreFilter.fixerUpper')
                }, {
                    keys: ['isActive'],
                    isActive: () => filterState.isActive === 0,
                    getLabel: () => this.molang('search.moreFilter.recentlySold')
                }, {
                    keys: ['hidePending'],
                    isActive: () => filterState.isActive && filterState.hidePending,
                    getLabel: () => this.molang('search.moreFilter.hidePendingInContract')
                }, {
                    keys: ['photos'],
                    isActive: () => filterState.photos,
                    getLabel: () => this.molang('search.moreFilter.hideListingWithoutPhotos')
                }
            ];
            return list;
        },
        checkFeedPropertyType(propertyTypes ,value) {
            return propertyTypes.includes(value);
        },
        getFeedFilters(state){
            let feedfilterState = state.filter;
            let feedFilterList = [{
                keys: ['minPrice', 'maxPrice'],
                isActive: () => feedfilterState.minPrice || feedfilterState.maxPrice,
                getLabel: () => {
                    const values = {min: feedfilterState.minPrice, max: feedfilterState.maxPrice};
                    return formatMinMaxLabel(values, '', formatPrice);
                }
            }, {
                keys: ['minBed', 'maxBed'],
                isActive: () => feedfilterState.minBed || feedfilterState.maxBed,
                getLabel: () => {
                    const values = {min: feedfilterState.minBed, max: feedfilterState.maxBed};
                    return formatMinMaxLabel(values, 'Bed');
                }
            },  {
                keys: ['propertyType'],
                isArray: true,
                value: feedPropertyTypes.singleFamily,
                isActive: () => this.checkFeedPropertyType(feedfilterState.propertyType , feedPropertyTypes.singleFamily),
                getLabel: () => this.molang('search.moreFilter.houses')
            }, {
                keys: ['propertyType'],
                isArray: true,
                value: feedPropertyTypes.condos,
                isActive: () => this.checkFeedPropertyType(feedfilterState.propertyType, feedPropertyTypes.condos),
                getLabel: () => this.molang('search.moreFilter.condo')
            }, {
                keys: ['propertyType'],
                isArray: true,
                value: feedPropertyTypes.multiFamily,
                isActive: () => this.checkFeedPropertyType(feedfilterState.propertyType, feedPropertyTypes.multiFamily),
                getLabel: () => this.molang('search.moreFilter.multiFamily')
            }, {
                keys: ['propertyType'],
                isArray: true,
                value: feedPropertyTypes.land,
                isActive: () => this.checkFeedPropertyType(feedfilterState.propertyType ,feedPropertyTypes.land),
                getLabel: () => this.molang('search.moreFilter.lotLand')
            }, {
                keys: ['propertyType'],
                isArray: true,
                value: feedPropertyTypes.mobile,
                isActive: () => this.checkFeedPropertyType(feedfilterState.propertyType, feedPropertyTypes.mobile),
                getLabel: () => this.molang('search.moreFilter.mobile')
            }, {
                keys: ['propertyType'],
                isArray: true,
                value: feedPropertyTypes.other,
                isActive: () => this.checkFeedPropertyType(feedfilterState.propertyType, feedPropertyTypes.other),
                getLabel: () => this.molang('search.moreFilter.other')
            }
        ];
        return feedFilterList;
        }
    }
};
