import geoMapper from '@/common/store/mapper/_geoMapper.js';
import format from '@/common/utilities/format';
import debounce from 'lodash/debounce';
import { mapActions, mapGetters, mapMutations } from 'vuex';
// needed for when we run on node versions lower than 15 in some CI environments
import { AbortController } from 'node-abort-controller';
import { getPathByFilter, getFilterByPath } from '../../../../widget/spec/filterhelper';
import { getGeoByPath } from '../../../../widget/spec/geohelper';

let fetchRequest;
let fetchAbort = new AbortController();
const abortFetch = () => {
    fetchAbort.abort();
    fetchAbort = new AbortController();
};
let searchTimer;
const SEARCH_BY = {
    SEARCH_GPS: 'search_gps',
    SEARCH_GEO: 'search_geo',
    SEARCH_RECENT: 'search_recent',
    SEARCH_SAVED: 'search_saved',
    SEARCH_DEFAULT: 'search_default',
    SEARCH_GAUTOCOMPLETE: 'search_gautocomplete',
    SEARCH_AGENT: 'search_agent',
    SEARCH_VETERAN: 'search_veteran',
    SEARCH_LENDER: 'search_lender',
    SEARCH_MARKET_TREND: 'search_market_trend',
    SEARCH_FAVORITE: 'search_favorite',
};

let timer;
export default {
    name: 'mvtSearchbox',
    inject: ['$eventBus', 'split'],
    emits: ['update:modelValue'],
    data() {
        return {
            delayLoad: 2,
            index: -1,
            categoryIndex: -1,
            searchText: '',
            suggestLinkList: [],
            defaultSuggestLinkList: [],
            isVisible: false,
            searchFilter: null,
            defaultText: '',
            focusIn: false,
            showMoreRecentSearch: false,
        };
    },
    props: {
        submitIcon: {
            type: String,
            default: 'icon-search',
        },
        defaultIcon: {
            type: String,
            default: 'icon-search',
        },
        type: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
        },
        modelValue: {
            type: Object,
            default: () => {
                return {};
            },
        },
        heroMode: {
            type: Boolean,
            required: false,
            default: false,
        },
        size: {
            type: String,
            required: false,
            default: 'default',
            validator: (val) => ['default', 'small', 'large'].includes(val),
        },
    },
    computed: {
        ...mapGetters('glb', ['glb', 'getLastSearch', 'getPrevSearch', 'getAutoSuggest', 'getSplit', 'getRecentSearchList']),
        noChanged() {
            return this.defaultText === this.modelValue.text && this.focusIn === false;
        },
        getIcon() {
            if (this.noChanged) {
                return this.defaultIcon;
            }
            return this.submitIcon;
        },
        _value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.defaultText = value.text;
                this.$emit('update:modelValue', value);
            },
        },
        searchSuggestions() {
            if (this.searchText.length > this.delayLoad) {
                return this.suggestLinkList;
            } else {
                return this.defaultSuggestLinkList;
            }
        },
        propertyTypeFilters() {
            if (this.glb.rentals) {
                return [
                    { name: 'House', filter: 'single-family' },
                    { name: 'Apartment', filter: 'condos' },
                    { name: 'Other', filter: 'other' },
                ];
            } else {
                return [
                    { name: 'House', filter: 'single-family' },
                    { name: 'Condo', filter: 'condos' },
                    { name: 'Multi', filter: 'multi-family' },
                ];
            }
        },
        getUserId() {
            return this.glb.user.id;
        },
    },
    watch: {
        modelValue: function (newVal, oldVal) {
            // watch when the value gets changed
            this._setText(this._value.text);
        },
    },
    beforeMount() {
        this.$eventBus.$on('eventbus-geosearch-apply', (params) => {
            this.geoSearch(params);
        });
    },
    mounted() {
        // focusin set this.focusInd to true
        this.$el.addEventListener('focusin', () => {
            this.focusIn = true;
        });
        // focusout set this.focusInd to false
        this.$el.addEventListener('focusout', () => {
            this.focusIn = false;
        });

        $(this.$el).on('click.searchbox', (e) => {
            e.stopPropagation();
        });
        $(this.$refs.input).one('focus', () => {
            if (this.getUserId) {
                let option = {
                    page: 1,
                    size: 3,
                    userId: this.getUserId,
                    savedSearchesSort: 'LAST_VIEW_TIME',
                    userFavoriteSort: '',
                    trigger: this.$options.name,
                };
                this.getAutoSuggestSavedSearch(option).then(() => {
                    this._initial();
                });
            } else {
                this._initial();
            }
        });
        this.updateSubscriptions(this.recordTolinks());
    },
    methods: {
        ...mapActions('glb', ['getAutoSuggestSavedSearch', 'getFavoriteListings']),
        ...mapMutations('glb', ['updateSubscriptions']),
        async updateSplit() {
            let getSearch =  $.getStorage('recentSearchList');
            if(!getSearch || getSearch.length <= 1){ //no search history
                return;
            }
            const result = await this.split.fetchTreatments(['movoto-show-more-searches-CW-2095'], { attributes: { pageType: this.glb.pageType }});
            this.showMoreRecentSearch = result['movoto-show-more-searches-CW-2095'] === 'on';
            this._getSuggestion();
        },
        _initial() {
            this._getSuggestion();
            $(this.$refs.input).on('focus.searchbox', () => {
                this.show();
            });
            $(this.$refs.input).on('keyup.searchbox', (e) => {
                if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                    return;
                } else if (e.key === 'ArrowDown') {
                    return this.next();
                } else if (e.key === 'ArrowUp') {
                    return this.prev();
                } else if (e.key === 'Escape') {
                    return this.hide();
                }
            });
            $(this.$refs.input).on(
                'input.searchbox',
                debounce((e) => {
                    this.defaultText = this._getText();
                    this.searchText = this._getText();
                    this.show();
                    this._getSuggestion();
                }, 300)
            );
            $(this.$refs.input).trigger('focus.searchbox');
        },
        _getText() {
            return $(this.$refs.input).val();
        },
        _setText: function (text) {
            text && $(this.$refs.input).val(text);
        },
        _coounter: function () {},
        _mappingDefaultSuggestion() {
            let autoSuggest = [
                {
                    icon: 'icon-location-arrow',
                    action: 'geoSearch',
                    text: 'Current Location',
                    url: '/for-sale/',
                    type: 'current',
                    searchBy: SEARCH_BY.SEARCH_GPS,
                },
            ];

            let recentlySearch = [];
            let getSearch =  $.getStorage('recentSearchList');
            if(this.showMoreRecentSearch && getSearch && getSearch.length > 0){
                getSearch.map(item => {
                    if(item.displayText){
                        recentlySearch.push({
                            text: item.displayText,
                            subText: item.subText,
                            icon: 'icon-search',
                            action: 'redirect',
                            url: item.url,
                            type: 'recentSearch',
                            searchBy: SEARCH_BY.SEARCH_RECENT,
                        });
                    } else {
                        recentlySearch.push({
                            text: item.text,
                            data: item,
                            icon: 'icon-search',
                            action: 'searchByInputText',
                            type: 'recentSearch',
                            searchBy: SEARCH_BY.SEARCH_RECENT,
                        });
                    }
                });
            }else {
                let getSearch = this.getPrevSearch || this.getLastSearch;
                if (getSearch && getSearch.displayText) {
                    let geo = getGeoByPath(getSearch.url);
                    let url = '/' + geo.path;
                    if (geo.geoType === 'CITY' && geo.lat && geo.lng) {
                        url += `@${geo.lat},${geo.lng}/`;
                    }
                    recentlySearch.push({
                        text: getSearch.displayText,
                        icon: 'icon-search',
                        action: 'redirectGeo',
                        url: url,
                        type: 'recentSearch',
                        searchBy: SEARCH_BY.SEARCH_RECENT,
                    });
                }
            }
            let subscription = this.recordTolinks();
            if (subscription && subscription.length > 0) {
                recentlySearch = recentlySearch.concat(subscription.slice(0, 4));
            }
            if (recentlySearch && recentlySearch.length) {
                recentlySearch[0].title = 'RECENT SEARCHES';
            }
            if (recentlySearch && recentlySearch.length) {
                autoSuggest = autoSuggest.concat(recentlySearch);
            }

            if (this.glb.savedSearches && this.glb.savedSearches.length) {
                let saveSearch = [];
                saveSearch = this.glb.savedSearches.slice(0, 3).map((item) => {
                    return {
                        text: item.input || item.searchName,
                        subText: this.createSubtext(item),
                        icon: 'icon-bookmark-o',
                        action: 'redirect',
                        url: '/' + item.url,
                        type: 'savedSearch',
                        searchBy: SEARCH_BY.SEARCH_SAVED,
                    };
                });
                saveSearch[0].title = 'SAVED SEARCH';
                autoSuggest = autoSuggest.concat(saveSearch);
            }

            if (this.glb.favoriteListings && this.glb.favoriteListings.length) {
                autoSuggest.push({
                    text: 'My Favorite Homes (' + this.glb.favoriteListings.length + ')',
                    href: this.glb.appUrl + 'my-movoto/favorites/',
                    icon: 'icon-heart',
                    type: 'savedHomes',
                    title: 'SAVED Homes',
                    searchBy: SEARCH_BY.SEARCH_FAVORITE,
                });
            }

            let footer = this._mappingFooterSuggestion(this.glb.geo);
            autoSuggest = autoSuggest.concat(footer);
            return autoSuggest;
        },
        _mappingGeoSuggestion(data) {
            var result = [];
            if (data && data.length) {
                var group = {};
                for (let i = 0; i < data.length; i++) {
                    var item = data[i];
                    var geoInfo = {
                        lat: item.location && item.location.lat,
                        lng: item.location && item.location.lon,
                        type: item.geotype,
                    };
                    var text = '';
                    var subText = '';
                    var icon = '';
                    var type = item.geotype;
                    var title = '';
                    if (!group[type]) {
                        group[type] = [];
                    }
                    if (type === 'city') {
                        if (group[type].length > 2) {
                            continue;
                        }
                        geoInfo.state = item.state;
                        geoInfo.city = item.city && item.city.toLocaleLowerCase();
                        text = geoInfo.city;
                        subText = geoInfo.state;
                        icon = 'icon-pin';
                    } else if (type === 'county') {
                        if (group[type].length > 0) {
                            continue;
                        }
                        geoInfo.state = item.state;
                        geoInfo.city = item.county && item.county.toLocaleLowerCase();
                        text = geoInfo.city;
                        subText = geoInfo.state;
                        icon = 'icon-map';
                    } else if (type === 'zipcode') {
                        if (group[type].length > 2) {
                            continue;
                        }
                        geoInfo.state = item.state;
                        geoInfo.city = item.city && item.city.toLocaleLowerCase();
                        geoInfo.zipcode = item.zipcode;
                        text = geoInfo.zipcode;
                        subText = geoInfo.state;
                        icon = 'icon-pin';
                    } else if (type === 'neighborhood') {
                        if (group[type].length > 0) {
                            continue;
                        }
                        geoInfo.state = item.state;
                        geoInfo.city = item.city && item.city.toLocaleLowerCase();
                        geoInfo.neighborhood = item.neighborhood && item.neighborhood.toLocaleLowerCase();
                        geoInfo.generatePath = true;
                        text = geoInfo.neighborhood;
                        subText = geoInfo.city + ', ' + geoInfo.state;
                        icon = 'icon-property-lot';
                    } else if (type === 'school') {
                        if (group[type].length > 0) {
                            continue;
                        }
                        geoInfo.state = item.state;
                        geoInfo.city = item.city && item.city.toLocaleLowerCase();
                        geoInfo.schoolName = (item.schoolname || (item.name && item.name.input) || item.full_name || '').toLocaleLowerCase();
                        geoInfo.schoolBoundaryId = item.schoolboundaryid;
                        geoInfo.schoolId = item.schoolid;
                        text = item.alternate_names;
                        subText = geoInfo.city + ', ' + geoInfo.state;
                        icon = 'icon-cap';
                    } else if (type === 'address') {
                        text = item.searchText;
                        icon = 'icon-pin';
                        geoInfo = item.geo;
                    }
                    group[type].push({
                        type: type,
                        title: title,
                        action: 'searchBySuggestion',
                        icon: icon,
                        text: text.replace(', USA', ''),
                        subText: subText,
                        value: geoInfo,
                        searchBy: `${SEARCH_BY.SEARCH_GEO}_${type}`,
                    });
                }

                if (group.address && group.address.length) {
                    group.address[0].title = 'ADDRESSES';
                    result = result.concat(group.address);
                }

                if (group.city && group.city.length) {
                    group.city[0].title = 'CITIES';
                    result = result.concat(group.city);
                }
                if (group.zipcode && group.zipcode.length) {
                    group.zipcode[0].title = 'ZIPCODE';
                    result = result.concat(group.zipcode);
                }

                if (group.neighborhood && group.neighborhood.length) {
                    group.neighborhood[0].title = 'NEIGHBORHOOD';
                    result = result.concat(group.neighborhood);
                }

                if (group.county && group.county.length) {
                    group.county[0].title = 'COUNTY';
                    result = result.concat(group.county);
                }

                if (group.school && group.school.length) {
                    group.school[0].title = 'SCHOOL';
                    result = result.concat(group.school);
                }
            }
            return result;
        },
        _mappingFooterSuggestion(geo) {
            var footerSuggest = [
                {
                    title: 'AGENTS',
                    text: 'top local Real Estate agents',
                    href: this.glb.appUrl + 'agents/',
                    icon: 'icon-user',
                    searchBy: SEARCH_BY.SEARCH_AGENT,
                },
                {
                    title: 'VETERANS',
                    text: 'Veterans Agents & VA Loans',
                    href: this.glb.appUrl + 'veterans/',
                    icon: 'icon-shield-star',
                    target: '_blank',
                    searchBy: SEARCH_BY.SEARCH_VETERAN,
                },
                {
                    title: 'LENDERS',
                    text: 'mortgage lenders',
                    href: this.glb.appUrl + 'mortgages/',
                    icon: 'icon-hand-rate',
                    searchBy: SEARCH_BY.SEARCH_LENDER,
                },
                {
                    title: 'MARKET TRENDS',
                    text: 'market trends',
                    href: this.glb.appUrl + 'market-trends/',
                    icon: 'icon-graph-increasing',
                    searchBy: SEARCH_BY.SEARCH_MARKET_TREND,
                },
            ];

            if (geo && geo.geoType) {
                if (geo.geoType === 'CITY') {
                    footerSuggest[3].href = `${this.glb.appUrl}${geo.cityPath}market-trends/`;
                    footerSuggest[3].text += ` in ${geo.city}`;
                    footerSuggest[0].href = `${this.glb.appUrl}agents/${geo.cityPath}`;
                    footerSuggest[0].text += ` in ${geo.city}`;
                } else if (geo.geoType === 'ZIPCODE') {
                    footerSuggest[3].text += ` in ${geo.zipcode}`;
                    footerSuggest[3].href = `${this.glb.appUrl}${geo.zipcodePath}market-trends/`;

                    footerSuggest[0].text += ` in ${geo.zipcode}`;
                    footerSuggest[0].href = `${this.glb.appUrl}agents/${geo.zipcodePath}`;

                    footerSuggest[2].text += ` in ${geo.zipcode}`;
                    footerSuggest[2].href = `${this.glb.appUrl}mortgages/?zipcode=${geo.zipcode}`;
                }
            }

            return footerSuggest;
        },
        getSearchType(geo, filter) {
            if (filter && filter.schoolId) {
                return 'school';
            } else {
                return geo ? geo.geoType : '';
            }
        },
        ga4Track(item) {
            if (!item || !item.searchBy) {
                console.error('searchBy is not defined in item');
                return;
            }
            //send ga4 event only, Google Recommended event, without any glb level CD
            //the the names of event must be defined by google.
            let searchType;
            let searchTerm;
            if ([SEARCH_BY.SEARCH_RECENT, SEARCH_BY.SEARCH_SAVED].includes(item.searchBy) && item.url) {
                //get path without domain from item.href
                let geo = getGeoByPath(item.url);
                let filter = getFilterByPath(item.url);
                searchType = this.getSearchType(geo, filter);
                if (item.searchBy === SEARCH_BY.SEARCH_SAVED) {
                    searchTerm = item.text;
                }
            } else if ([SEARCH_BY.SEARCH_AGENT, SEARCH_BY.SEARCH_FAVORITE, SEARCH_BY.SEARCH_VETERAN, SEARCH_BY.SEARCH_LENDER, SEARCH_BY.SEARCH_MARKET_TREND].includes(item.searchBy)) {
                searchType = item.searchBy.replace('search_', '');
            } else if (item.searchBy.indexOf(SEARCH_BY.SEARCH_GEO) >= 0) {
                searchType = item.searchBy ? item.searchBy.replace(SEARCH_BY.SEARCH_GEO + '_', '') : '';
            }

            if (!searchType) {
                searchType = item.searchBy || '';
            }
            if (!searchTerm) {
                searchTerm = item.text + (!item.subText || /^\s*$/.test(item.subText) ? '' : ' ' + item.subText);
            }

            this.$eventBus.$emit(
                'ga4',
                {
                    event: 'search',
                    en: 'search',
                    ec: item.searchBy,
                },
                {
                    pageData: {
                        msp_term: searchTerm ? format.capitalizeTitle(searchTerm) : '',
                        msp_type: searchType ? searchType.toUpperCase() : '',
                    },
                }
            );
        },
        action(item) {
            $('#header').removeClass('full-width-searchbox-list');
            this.searchFilter = null;
            try {
                this.ga4Track(item);
            } catch (e) {
                window.sendNodeLog && window.sendNodeLog(`client-error-ga4track`, window.location.href, 0, e);
            }
            if (searchTimer) clearTimeout(searchTimer);
            searchTimer = setTimeout(() => {
                switch (item.action) {
                    case 'geoSearch':
                        this.geoSearch();
                        break;
                    case 'redirect':
                        this.redirectTo(item.url);
                        break;
                    case 'redirectGeo':
                        this.redirectTo(item.url, true);
                        break;
                    case 'searchBySuggestion':
                        this.searchBySuggestion(item);
                        break;
                    case 'searchByInputText':
                        const newVal = (item && item.data) || null;
                        if (!newVal) {
                            return;
                        }
                        this.$eventBus.$emit('eventbus-search', newVal);
                        break;
                    default:
                        if (item.href) {
                            if (item.target) {
                                this.$eventBus.$emit('ga', { ec: this.$options.name, en: 'click', el: item.text, props: { link_name: item.text } });
                                window.open(item.href, item.target);
                            } else {
                                $.redirect(item.href);
                            }
                        }
                        break;
                }
            }, 500);
            this.hide(true);
        },
        createSubtext(item) {
            var bedText = '';
            var PriceText = '';
            var iconText = '';
            var subText = '';
            if (item.minBed && $.notification.valideBed(item.propertyType)) {
                bedText = item.minBed + '+ Beds';
            }
            if (item.minPrice && item.maxPrice) {
                if (!isNaN(item.minPrice) && !isNaN(item.maxPrice)) {
                    PriceText = (bedText ? ', ' : '') + '$' + format.friendlyPrice(item.minPrice) + ' to ' + format.friendlyPrice(item.maxPrice);
                } else {
                    const minPrice = Number(item.minPrice.replace(/[^0-9\.]+/g, ''));
                    const maxPrice = Number(item.maxPrice.replace(/[^0-9\.]+/g, ''));
                    if (minPrice && !maxPrice) {
                        PriceText = (bedText ? ', ' : '') + 'More than $' + format.friendlyPrice(minPrice);
                    } else if (!minPrice && maxPrice) {
                        PriceText = (bedText ? ', ' : '') + 'Less than $' + format.friendlyPrice(maxPrice);
                    } else if (minPrice && maxPrice) {
                        PriceText = (bedText ? ', ' : '') + '$' + format.friendlyPrice(minPrice) + ' to $' + format.friendlyPrice(maxPrice);
                    } else {
                        PriceText = '';
                    }
                }
            }
            subText = bedText + PriceText;
            if (item.propertyType.indexOf('SINGLE_FAMILY_HOUSE') >= 0) {
                iconText += '<i class="icon-property-single-family"></i>';
            }
            if (item.propertyType.indexOf('CONDO') >= 0) {
                iconText += '<i class="icon-property-condo"></i>';
            }
            if (item.propertyType.indexOf('MULTI_FAMILY') >= 0) {
                iconText += '<i class="icon-property-multi-family"></i>';
            }
            if (item.propertyType.indexOf('LAND') >= 0) {
                iconText += '<i class="icon-property-lot"></i>';
            }
            if (item.propertyType.indexOf('MOBILE') >= 0) {
                iconText += '<i class="icon-property-mobile"></i>';
            }
            if (item.propertyType.indexOf('OTHER') >= 0) {
                iconText += '<i class="icon-property-other"></i>';
            }
            subText = iconText + ' ' + subText;
            return subText;
        },
        recordTolinks() {
            var data = $.notification.getRecords();
            data.sort((a, b) => {
                return a.zipcode - b.zipcode;
            });
            return data.reduce((list, record) => {
                if (record.disabled) {
                    return list;
                }
                var text = '';
                text = record.zipcode;
                var subText = this.createSubtext(record);
                list.push({
                    icon: 'icon-search',
                    value: record.zipcode,
                    text: text,
                    subText: subText,
                    zipcode: record.zipcode,
                    stateCode: record.stateCode,
                    href: $.notification.getUrlByRecord(record),
                    type: 'recentSearch',
                    searchBy: SEARCH_BY.SEARCH_RECENT,
                });
                return list;
            }, []);
        },
        geoSearch(params) {
            //
            var gpsPath = `/for-sale/`;
            if (this.glb.rentals) {
                gpsPath = '/rentals/';
            }
            if (params && params.gpsPath) {
                gpsPath = params.gpsPath;
            }
            $.geoServiceLocation().then((latlng) => {
                if (latlng) {
                    const url = `${gpsPath}@${latlng.lat},${latlng.lng}/`;
                    this.redirectTo(url);
                    this.$eventBus.$emit('eventbus-showmap', true);
                } else {
                    console.log('Cannot get current localtion info');
                    if (location.pathname.indexOf(gpsPath) !== 0) {
                        this.redirectTo(gpsPath);
                    }
                    return;
                }
            });
        },
        redirectTo(path, isGeo) {
            if (this.glb.pageType === 'vmapsearch') {
                if (isGeo) {
                    let filter = getFilterByPath(window.location.pathname);
                    path += `${getPathByFilter(filter, true)}`;
                }

                this.$eventBus.$emit('eventbus-redirect-keepfilter', path);
            } else {
                $.redirect(location.origin + path);
            }
        },
        searchBySuggestion(item) {
            if (item) {
                if (item.value.address_components && item.value.address_components.length > 1) {
                    this._value = {
                        text: item.text + (item.subText ? ', ' + item.subText : ''),
                        geo: item.value,
                        isGoogleGeo: true,
                    };
                } else if (item.value && item.value.isGoogleGeo) {
                    this._searchByText(item.text);
                } else {
                    this._value = {
                        text: item.text + (item.subText ? ', ' + item.subText : ''),
                        geo: item.value,
                        isGoogleGeo: false,
                        extraPath: item.extraPath && `${item.extraPath}/`,
                    };
                }
            }
        },
        _getGeoSuggestionData() {
            return $.getAutoComplete(this.searchText).then((result) => {
                var suggestionList = [];
                if (result && result.length) {
                    result.forEach((obj) => {
                        suggestionList.push({
                            searchText: obj.text,
                            geo: { isGoogleGeo: true },
                            geotype: 'address',
                        });
                    });
                }
                return suggestionList;
            });
        },
        _getSuggestion() {
            var displayText = this.searchText;
            if (this.searchText && displayText && displayText.length > this.delayLoad) {
                if (/(\d+\s+[A-Za-z]+\s+)|(\d+\s+[A-Za-z]{3,})/.test(displayText)) {
                    this._getGeoSuggestionData().then((dict) => {
                        if (dict && dict.length) {
                            this.suggestLinkList = this._mappingGeoSuggestion(dict);
                        } else {
                            this.suggestLinkList = [];
                        }
                    });
                    return;
                }

                if (fetchRequest && fetchRequest.state === 'pending') {
                    abortFetch();
                }
                var lastSearch = $.getStorage('lastSearch');
                const urlParams = new URLSearchParams({
                    key: displayText,
                    state: (this.glb.geo && this.glb.geo.state) || $.getCookie('lastSearchState'),
                });
                fetchRequest = fetch(`${this.glb.appUrl}api/geo/autosearch_v2/?${urlParams}`, { signal: fetchAbort.signal })
                    .then((response) => response.json())
                    .then((data) => {
                        fetchRequest.state = 'complete';
                        var dict = data;
                        this.suggestLinkList = [];
                        if (typeof data === 'string') {
                            dict = JSON.parse(data);
                        }
                        if (dict && dict.length) {
                            this.suggestLinkList = this._mappingGeoSuggestion(dict);
                            let footer = this._mappingFooterSuggestion(this.suggestLinkList && this.suggestLinkList.length ? geoMapper(this.suggestLinkList[0].value) : '');
                            this.suggestLinkList = this.suggestLinkList.concat(footer);
                        } else {
                            this._getGeoSuggestionData().then((dict) => {
                                if (dict && dict.length) {
                                    this.suggestLinkList = this._mappingGeoSuggestion(dict);
                                } else {
                                    this.suggestLinkList = [];
                                }
                            });
                        }
                    })
                    .catch((err) => {
                        // log error to console only if request was not aborted on purpose, 20 is the code for AbortError https://developer.mozilla.org/en-US/docs/Web/API/DOMException
                        if (err.code !== 20) {
                            console.error(err);
                        }
                    });
                fetchRequest.state = 'pending';
            } else {
                if (fetchRequest && fetchRequest.state === 'pending') {
                    abortFetch();
                }
                this.defaultSuggestLinkList = this._mappingDefaultSuggestion();
            }
            this.index = -1;
        },
        _searchByText(text) {
            $.geoServiceSearch(text).then((results) => {
                if (results && results.length) {
                    var geoObj;
                    if (results[0].geometry && results[0].geometry.location) {
                        if (results[0].partial_match === true) {
                            geoObj = {
                                address_components: [],
                            };
                        } else {
                            geoObj = {
                                address_components: results[0].address_components,
                                lat: results[0].geometry.location.lat(),
                                lng: results[0].geometry.location.lng(),
                            };
                        }
                    } else {
                        geoObj = results[0];
                    }
                    this._value = {
                        text: text,
                        isGoogleGeo: true,
                        //geo: results[0]
                        geo: geoObj,
                    };
                } else {
                    this._value = {
                        text: text,
                        isGoogleGeo: true,
                        geo: null,
                    };
                }
            });
        },
        _search(setTop) {
            var text = this._getText();
            var isMlsNumberFlag = (/^([a-zA-Z\d]+)?[\d]{4,}$/.test(text) && text.length > 5 && text.indexOf(' ') < 0) || /^\d{2}-\d{3,}$/.test(text);
            var suggest = null;
            if (this.searchSuggestions && this.searchSuggestions.length > 0) {
                let index = this.index != -1 ? this.index : 0;
                suggest = this.searchSuggestions[index];
                this.action(suggest);
            } else if (text && this.searchText.length > this.delayLoad) {
                if (/Current Location/i.test(text)) {
                    return;
                }
                if (isMlsNumberFlag) {
                    this._value = {
                        text: text,
                        isMlsNumber: true,
                        geo: {},
                    };
                } else {
                    this._searchByText(text);
                }
                this.hide(setTop);
            } else {
                this.$emit('noinput');
            }
        },
        update(text) {
            this._setText(text);
        },
        onSubmit() {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                this._search(true);
            }, 300);
        },
        hide(resetTop) {
            $(document).off('click.searchbox', this.hide);
            if (this.isVisible) {
                this.isVisible = false;
                this.showGroupedList = false;
                $.closeOverlayer('searchbox-active', resetTop);
                setTimeout(() => {
                    this.searchText = '';
                    this.index = -1;
                }, 10);
            }
        },
        show() {
            setTimeout(() => {
                if (!this.isVisible) {
                    this.isVisible = true;
                    this.updateSplit();
                    $.openOverlayer('searchbox-active');
                }
                $(document).off('click.searchbox');
                setTimeout(() => {
                    $(document).on('click.searchbox', this.hide);
                }, 100);
            });
        },
        next() {
            if (this.index >= this.searchSuggestions.length) {
                this.index = 0;
            } else {
                this.index += 1;
            }
        },
        prev() {
            if (this.index <= 0) {
                this.index = this.searchSuggestions.length - 1;
            } else {
                this.index -= 1;
            }
        },
    },
    beforeUnmount() {
        $(document).off('dom.searchbox.hide' + this.vnumber);
    },
};
