<template>
<section class="mvt-compliance">
    <div class="grid fixed xs-grid-cols-1">
        <template v-if="hasRentalDisclaimer">
            <template v-for="item in getDisclaimer()" >
                <div class="paragraph"  v-if="item" v-html="item"></div>
            </template>
            <div class="paragraph">
                Movoto <a :href="glb.appUrl + 'about/terms-of-use/'" target="_blank" class="link">Terms of use</a> & <a :href="glb.appUrl + 'about/privacy-policy/'" target="_blank" class="link">Privacy Policy</a>, <a :href="glb.appUrl + 'about/ca_privacy/'" target="_blank" class="link">California Privacy Info</a>. 
                Copyright © 2005 - 2021 Movoto, Inc. All rights reserved. <a href="https://www.hud.gov/library/bookshelf11/hudgraphics" target="_blank" class="link" rel="nofollow noreferrer">Equal Housing Opportunity</a> CalDRE #01517281
            </div>
            <div class="paragraph">
                Movoto Real Estate is committed to ensuring accessibility for individuals with disabilities.
                We are continuously working to improve the accessibility of our web experience for everyone.
                We welcome feedback and accommodation requests, please submit them <a @click="openAccessibilityHotlead" href="javascript:;" rel="nofollow" role="button" class="link">here</a>.
            </div>
        </template>
        <template v-else>
            <div  class="paragraph">
                <span v-html="getMLSInfo()">
                </span>
                {{molang('search.compliance.start')}}
                <a :href="glb.appUrl + 'about/terms-of-use/'">{{molang('search.compliance.terms')}}</a> & <a :href="glb.appUrl + 'about/privacy-policy/'">{{molang('search.compliance.privacy')}}</a>, <a :href="glb.appUrl + 'about/ca_privacy/'" target="_blank" class="link">{{molang('search.compliance.caPrivacy')}}</a>.
                Copyright © 2005 - {{getYear}} Movoto, Inc.
                {{molang('search.compliance.rights')}} <a rel="nofollow" href="https://www.hud.gov/library/bookshelf11/hudgraphics">{{molang('search.compliance.equal')}}</a> <img loading="lazy" style="height: 12px; width: 12px"  alt="CalDRE" :src="glb.cdnUrl + 'images/desktop/fheo75.gif'"> CalDRE #01517281
            </div>
            <div class="paragraph">
                {{molang('search.common.accessibilityRequestDisclaimer')}}
                <a @click="openAccessibilityHotlead" role="button" href="javascript:;" rel="nofollow" class="link">here</a>.
            </div>
            <template v-for="item in getDisclaimer()" >
                <div class="paragraph"  v-if="item" v-html="item"></div>
            </template>
        </template>
        <div class="paragraph">
            Requests for information regarding mortgage products will be directed to Movoto.com affiliate, OL Financial LLC dba Movoto Home Loans - a licensed Mortgage Broker (NMLS #1971703), or, based on your location, may be redirected to a participating mortgage marketing partner that may pay Movoto Home Loans for any consumer contact information provided through this site.
        </div>
    </div>
</section>
</template>

<script src="./mvt-compliance.js"></script>
