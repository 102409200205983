<template>
    <div id="container" type="vappdownload">
        <div class="page"  v-ga:request="{pageType:'vappdownload', state: {}}" v-ga:page="{pageType:'vappdownload', glb: glb, state: {}}">
            <mvtHeader />
            <!-- Add UI -->
            <mvtDownloadApp :appUTM="utm_campaign" />
            <mvtFooter />
        </div>
        <mvtPopSignin />
        <mvtDialogCenter></mvtDialogCenter>
    </div>
</template>

<script src="./page-mobile.js"></script>

<style lang="scss">
@import './page-mobile.scss';
</style>