import {mapGetters} from 'vuex';
import mvtSocial from '@/common/components/mvtsocial/mvt-social.vue';

export default {
    name: 'mvtWelcomeBack',
    inject: ['$eventBus'],
    components: {
        mvtSocial
    },
    props: {
        options: {
            type: Object,
            default: {
                loginType: '',
                loginMessage: '',
                requestName: '',
                trigger: '',
            }
        }
    },
    computed: {
        ...mapGetters('glb', ['glb']),
        getLoginType(){
            return this.options.loginType;
        },
        getLoginMsg(){
            return this.options.loginMessage;
        }
    },
    methods: {
        switchComponent: function(name) {
            this.$emit('switchComponent', name);
        }
    }
};
