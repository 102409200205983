

<template>
    <div>
        <component v-if="componentName" :options="options" :is="componentName" @switchComponent="updateComponentName"></component>
    </div>
</template>

<script src="./mvt-authenticate.js"></script>
<style lang="scss">
        @import "./mvt-authenticate.scss";
</style>
