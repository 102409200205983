<template>
  <slot :treatments="treatments" :isReady="isReady"></slot>
</template>

<script>
import { defineComponent, inject, ref, watch, onMounted } from "vue";

export default defineComponent({
  name: "SplitTreatments",
  props: {
    names: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      default: 'client'
    },
    attributes: {
      type: Object,
      required: false,
      default: {}
    },
    withConfig: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {

    const treatments = ref({});
    const isReady = ref(false);
    const split = inject("split");

    const handleSplitReady = async () => {
      if (split.ready.value === true && props.mode === "client") {
        const fetchedTreatments = await split.fetchTreatments(
            props.names,
            { attributes: props.attributes, withConfig: props.withConfig }
        );
        treatments.value = fetchedTreatments;
        isReady.value = true;
      } else if(props.mode === 'server') {
        const fetchedServerTreatments = split.fetchServerTreatments(
            props.names,
            props.withConfig
        )
        treatments.value = fetchedServerTreatments;
        isReady.value = true;
      }
    };

    // Watch for changes in the Split client (in case the user changes)
    watch(split.ready, (newReadyStatus) => {
      if (newReadyStatus) {
        handleSplitReady();
      }
    });

    watch(() => props.attributes, (newValue) => {
      if (newValue) {
        handleSplitReady();
      }
    });

    /* Initialize Split as early as possible */
    if(split.ready.value === true || props.mode === "server") {
      handleSplitReady();
    }

    return {
      treatments,
      isReady,
    };
  },
});

</script>