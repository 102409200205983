<template>
    <div class="mvt-appbanner">
        <div class="flex small inline bg-main middle left">
             <div class="flex middle left">
                <button @click="hide" aria-label="Dismiss Banner"><i class="icon-times"></i></button>
                <div class="mvt-appbanner-icon">
                    <img loading="lazy" :src="glb.cdnUrl + 'vimages/movoto-light.svg'" alt="Movoto">
                </div>
                <div  @click="openAppInstall" class="text-left">
                    <div class="f5">Movoto App</div>
                    <div class="f7">
                        <i v-for="index in 5" :key="index" class="icon-star text-tip"></i>
                        <span>(50k)</span>
                    </div>
                </div>
             </div>
            <div>
                <button @click="openAppInstall" class="btn alternative small">
                    <span v-if="isAppInstalled">Open</span>
                    <span v-else>Install</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script src="./mvt-appbanner.js"></script>

<style lang="scss">
@import './mvt-appbanner.scss';
</style>
