<template>
    <div class="mvt-social grid xs-grid-cols-1 center middle">
        <div class="signin-google">
            <div id="signinDiv" v-if="loginType === 'google' || loginType === 'all'"></div>
        </div>
        <a class="signin-facebook" rel="nofollow" @click="facebookLogin()" v-if="loginType === 'facebook' || loginType === 'all'">
            <i class="icon-facebook"></i> <span>{{textfacebook}} </span> </a>
        <a class="signin-apple" href="javascript:void(0)" rel="nofollow" @click="appleLogin()" v-if="loginType === 'apple' || loginType === 'all'">
            <i class="icon-apple"></i> <span> {{textapple}} </span></a>
    </div>
</template>

<script src="./mvt-social.js"></script>

<style lang="scss">
@import "./mvt-social.scss";
</style>

