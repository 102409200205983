import { mapGetters } from 'vuex';
import mvtPopSignin from '@/common/components/mvtpopsignin/mvt-popsignin.vue';
import mvtDownloadApp from '@/common/components/mvtdownloadapp/mvt-downloadapp.vue';
import mvtDialogCenter from '@/common/components/mvtdialogcenter/mvt-dialogcenter.vue';
import mvtHeader from '@/common/components/mvtheader/mvt-header.vue';
import mvtFooter from '@/common/components/mvtfooter/mvt-footer.vue';

const isSSR = typeof window === 'undefined';

export default {
    name: 'pageMobile',
    inject: ['$eventBus'],
    components: {
        mvtPopSignin,
        mvtDialogCenter,
        mvtDownloadApp,
        mvtHeader,
        mvtFooter,
    },
    data() {
        return {
            phone: '',
            buttonText: 'Text Me The App',
            success: false,
            utm_campaign: !isSSR && window.__INITIAL_STATE__.utm_campaign,
        };
    },
    computed: {
        ...mapGetters('glb', ['glb']),
    },
    mounted() {
        this.$eventBus.$emit('eventbus-pageLoaded');
    },
    beforeUnmount() {},
};
